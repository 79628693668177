.blog-page {
  #header {
    position: relative;
    background: #03141f; } }

/* Blog Single */
.blog-single {
  padding: 70px 0;
  background: #f5f5f5; }

.blog-wrapper {

  .blog-thumb {
    position: relative;
    margin-bottom: 20px;
    img {
      width: 100%; } } }


.blog-wrapper {

  .blog_post {
    margin-bottom: 20px; }
  .post-meta {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
    li {
      display: inline;
      padding-right: 20px;
      a {
        text-decoration: none;
        font-weight: 500;
        color: $color_theme;
        font-weight: 600;
        transition: all 0.3s ease-in-out;

        i {
          margin-right: 10px; } } } }


  h2 {
    color: #222;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 700;
    @include font-size(28); }

  p {
    margin-bottom: 20px;
    color: #545454; }

  blockquote {
    font-style: italic;
    color: #33ceff;
    font-weight: 600;
    border-left: none;
    border-top: 1px solid $color_theme;
    border-bottom: 1px solid $color_theme;
    padding: 15px;
    @include font-size(16); }

  .tag-area {
    i {
      margin-right: 10px;
      color: $color_theme; }

    a {
      text-decoration: none;
      color: #111;
      margin-right: 5px;
      transition: all 0.3s ease-in-out;
      font-weight: 700;

      &:hover {
        color: $color_theme; } } } }

.blog-sub-title {
  @include font-size(24);
  color: #111;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 15px;

  &:after {
    position: absolute;
    background: $color_theme;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 50px;
    content: ''; } }

.athor {
  padding: 10px;
  margin-bottom: 30px;



  .athor-inner {
    border: 1px solid #ddd;
    padding: 10px;
    .thumb {
      float: left; } }

  .content {
    margin-left: 110px;

    h4 {
      @include font-size(20);
      font-weight: 700;
      margin-bottom: 10px; }

    span {
      color: #999;
      display: block;
      margin-bottom: 10px;
      font-weight: 600; } } }



ol {
  margin: 0;
  list-style: none;

  &.comment-list {
    padding: 0; } }

article {
  padding: 15px 0;
  position: relative;

  .reply {
    position: absolute;
    right: 0;
    bottom: 15px;
    a {
      text-decoration: none;
      color: $color_theme;

      i {
        margin-left: 10px; } } } }


article {
    border-bottom: 1px solid #eee;
    paddin: 20px 0; }

.comment-meta {
  img {
    float: left; }
  a {
    margin-left: 110px;
    text-decoration: none;
    color: #ccc;
    margin-bottom: 10px;
    display: block;
    font-weight: 600;
    i {
      margin-right: 5px; }


    &.url {
      @include font-size(16);
      color: #111;
      text-decoration: none;
      font-weight: 700;
      margin-bottom: 5px; } } }

.comment-content {
  margin-left: 110px;

  p {
    margin-bottom: 20px;
    color: #676767; } }


.comment-respond {
  .comment-reply-title {
    @include font-size(22);
    color: #111;
    font-weight: 700;
    margin: 20px 0; }

  .comment-form {
    p {
      width: 32%;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 15px;

      &:nth-child(3) {
        margin-right: 0; }

      input {
        width: 100%;
        padding: 5px 10px;
        outline: none;
        border: 1px solid #ddd;
        transition: 0.3s ease-in-out;

        &:hover {
          border-color: $color_theme; } } }

    .comment-form-comment {
      padding-right: 5px;
      width: 100%;
      textarea {
        width: 100%;
        height: 150px;
        outline: none;
        padding: 10px;
        border: 1px solid #ddd;
        transition: 0.3s ease-in-out;

        &:hover {
          border-color: $color_theme; } } } }

  #submit {
    background: $color_theme;
    border: none;
    color: #FFF;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;

    &:hover, &:focus {
      background: #111; } } }

.more-blog {
  display: block;
  margin: 0 auto;
  color: #FFF;
  padding: 10px 0;
  background: $color_theme;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  width: 150px;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    background: #111;
    color: #FFF;
    text-decoration: none; } }
