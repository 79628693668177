
/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: $color_theme;
  width: 42px;
  height: 42px;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  z-index: 1000;


  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 11px;
    top: 9px;
    font-size: 19px;
    transition: all 0.3s ease; }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    i {
      color: #fff;
      top: 5px; } } }

.back-top {
 display: block; }


.header_wrapper {
  width: 94%;
  margin: 0 auto;
  max-width: 1140px;
  position: relative; }

.dialog {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  pointer-events: none;
  z-index: 9999; }


.dialog, .dialog__overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.dialog--close {
  .dialog__overlay {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s; } }

.dialog--open {
  .dialog__overlay {
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s; } }

.dialog--open {
  .dialog__overlay {
    opacity: 1;
    pointer-events: auto; } }

.dialog__overlay {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-backface-visibility: hidden; }

.dialog.dialog--open {
  .dialog__content {
      opacity: 1; } }

.dialog--open {
  .dialog__content {
    pointer-events: auto; } }

.dialog__content {
  padding: 0;
  background: transparent; }

.dialog__content {
  width: 80%;
  max-width: 520px;
  min-width: 290px;
  background: #fff;
  padding: 50px;
  text-align: center;
  position: relative;
  z-index: 5;
  opacity: 0; }



.dialog--open {
  .dialog-inner {
    opacity: 1;
    transition: opacity 0.3s; } }

.dialog-inner {
  opacity: 0;
  background: #fff; }

.dialog.dialog--open h2 {
  -webkit-animation: anim-elem-1 0.7s ease-out both;
  -o-animation: anim-elem-1 0.7s ease-out both;
  animation: anim-elem-1 0.7s ease-out both; }

.dialog h2 {
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  padding: 0 0 15px;
  text-transform: uppercase; }

#search_popup_wrapper form {
  margin: 10px 0;
  position: relative; }

.search-form {
  position: relative;
  width: 100%; }

.search-form {
  .search-field {
    width: 100%; } }

input[type="search"] {
  padding-left: 20px;
  padding-right: 35px;
  height: 45px;
  line-height: 1;
  color: #aaa;
  border: 1px solid transparent;
  transition: all 0.3s;
  box-sizing: border-box;
  background-color: #f7f7f7;
  vertical-align: top;
  color: #111;
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: $color_theme; } }



.search-form {
  .search-submit {
    position: absolute;
    width: 45px;
    height: 45px;
    right: 0;
    top: 0;
    padding: 0;
    font-size: 16px;
    background-color: transparent;
    color: #bababa;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color_theme; } } }

.dialog {
  &.dialog--open {
    a {
      position: absolute;
      border: none;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 35px !important;
      background-color: #eee;
      color: #000;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color_theme; } } } }

.navbar-toggle {
  .icon-bar {
    background: #FFF; } }


/* Mobile Menu */

#mobile-header {
  text-align: center;
  background: rgba(0,0,0, 0.8);
  padding: 8px 10px;
  height: 50px;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 10000;

  .main-logo {
    width: 100px;
    display: inline-block;
    img {
      width: 100%; } } }

.menu-container {
  float: right; }


.cbp-spmenu-right {
  right: -270px; }

.cbp-spmenu-vertical {
  a {
    border-bottom: none;
    &:hover {
      background: none; } } }

.cbp-spmenu-push-toleft {
  &:before {
    background: rgba(0,0,0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 10000; } }

.cbp-spmenu-push-toleft {
  left: -270px; }

.dt-social-link {
  margin: 0;
  padding: 0;
  list-style: none;


  li {
    display: inline-block;
    margin-right: 8px;
    text-decoration: none;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid $color_theme;
    transition: all 0.3s ease-in-out;

    &:last-child {
        padding: 0; }
    a {
      color: $color_theme;
      transition: all 0.3s ease-in-out;
      display: block;
      transition: all 0.3s ease-in-out;
      padding: 0;

      i {
        @include font-size(12);
        @include line-height(28); } }


    &:hover {
      background: $color_theme !important;

      a {
        color: #FFF; } } } }

#mobile-wrapper {
  width: 270px;
  background: #222533;
  position: fixed;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  height: 100vh; }

.mobile_menu {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;


  li {
    border-bottom: 1px solid #06191f;
    border-top: 1px solid #313131;

    &:first-child {
      border-top: none; }

    &:last-child {
      border-bottom: none; }

    a {
      display: block;
      color: #FFF;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out; } } }

.menu-item-has-children {
  .sub-menu {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      background: #1d1d1d; } } }

.menu-item-has-children {
  &.children-menu-visible {
    .sub-menu {
      display: block;
      transition: all 0.3s ease-in-out; } } }

.mobile_menu_search {
  padding: 0 20px;
  margin-bottom: 30px;

  #search {
    border: 1px solid #ddd;
    position: relative;
    input[type="text"] {
      border: none;
      outline: none;
      background: transparent;
      padding: 7px 10px;
      width: 85%;
      color: #FFF; } }

  #searchsubmit {
    background-color: transparent;
    border: none;

    &:focus, &:hover {
      outline: none; }

    &:hover {
      color: $color_theme; } } }

.menu-toggle {
  position: relative;
  width: 30px;
  height: 23px;
  float: right;
  margin: 6px;

  &:hover {
    cursor: pointer; }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 1px;
    background-color: $color-theme;
    transition: all 0.5s ease-in-out;

    &:nth-child(2) {
      top: 50%;
      margin-top: -1px; }

    &:nth-child(3) {
      top: auto;
      bottom: 0; } }

  &.active {
    > div {
      &:nth-child(1) {
        transform: translateY(10px) rotate(135deg); }

      &:nth-child(2) {
        opacity: 0; }

      &:nth-child(3) {
        transform: translateY(-12px) rotate(-135deg); } } } }


