#testimonial {
  padding: 50px 0;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  .section-title {
    color: #FFF; } }

.testimonial-slider {
  .testional {
    .item {
      position: relative;
      padding: 50px 0;
      text-align: center;


      .cliten-thumbs {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 4px solid $color_theme;
        margin: 0 auto 20px;

        img {
          width: 100%;
          border-radius: 50%; } }

      .testi-content {


        p {
          color: #ddd;
          margin-bottom: 60px;
          font-style: italic;
          letter-spacing: 1px; } }


      h4 {
        color: $color_theme;
        text-transform: uppercase;
        @include font-size(16);
        margin-bottom: 10px;
        font-weight: 700; }

      span {
        color: #FFF; } } }

  .owl-nav {
    display: none; } }

.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        transition: all 0.3s ease; }

      &.active, &:hover {
        span {
          background: $color_theme;
          height: 10px;
          width: 15px; } } } } }

/* Testimonial */
#feedback-slider2 {
  .item {
    background: rgba(255,255,255, 0.8);
    padding: 30px;

    .testi-content {


      p {
        color: #111; } }



    h4 {
      color: $color_theme; }


    span {
      color: #333; } } }
