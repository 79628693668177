#pricing_table {
  padding: 50px 0;
  text-align: center;
  background: #f5f5f5;
  overflow: hidden; }

.table {
  background: #FFF;
  box-shadow: 0px -3px 29px -2px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 20px;

  .pricing_plan {
    border: 1px solid #ededed;
    .price {
      background: $color_theme;
      padding-bottom: 20px;

      h4 {
        color: #111;
        text-transform: uppercase;
        @include font-size(26);
        font-weight: 700;
        padding: 10px 0;
        background-color: rgba(255,255,255, 0.2); }


      p {
        color: #eee;
        @include line-height(24);
        margin-bottom: 15px; }

      .plan {
        display: inline-block;
        height: 120px;
        width: 120px;
        border-radius: 50%;

        padding: 29px 0;
        background: #FFF;

        h3 {
          @include font-size(40);
          color: #000;
          font-weight: 600;
          margin-bottom: 0; }

        span {
          font-size: 14px; } } }

    .configuration {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        &:nth-child(odd) {
          background: #f8f8f8; }

        &:last-child {
          padding: 17px 0; }

        &.none {
          color: red; }

        i {
          margin-right: 10px; }

        .gp-btn {
          border-radius: 30px;
          background: #FFF;
          transition: all 0.3s ease-in-out;
          color: #FFF;
          background: $color_theme;
          text-decoration: none;


          &:hover {
            background: #111;
            color: #FFF;
            border-color: transparent; } } } } } }

