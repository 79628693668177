html {
  font-size: 14px; }

body {
  @include font-smoothing;
  color: $color_text;
  font-family: $font_primary;
  font-size: 14px; }

p {
  color: $color_text;
  @include font-size(14);
  @include line-height(26); }


.section-title {
  text-transform: uppercase;
  color: #111;
  margin-top: 0;
  position: relative;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 30px;

  &:after {
    position: absolute;
    content: ' ';
    background: $color_theme;
    width: 70px;
    height: 3px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); } }


.caption {
  color: #000;
  margin-bottom: 40px;
  @include line-height(24); }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.7); }

h1,h2,h3,h4,h5 {
  color: $color_header;
  margin-top: 0;
  font-family: 'PT Sans', sans-serif; }



