/*!
  Theme Name: Engage
  Theme URI: http://www.decentthemes.com/products/corpo-wordpress/
  Author: Golden Plus Theme
  Author URI: http://www.mominul.me/
  Description: The Ultimate Financial / Business Template
  Version: 1.1.1
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: engage
  Tags:
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base rules
  # Library
    ## Example
  # Elements
    ## Accessibility
    ## Buttons
  # Navigation Menu
    # Primary Menu
  # Sections
    ## Loading
    ## Header
    ## Banner
    ## About Us
    ## Skllis
    ## Service
    ## Portfolio
    ## CountUp
    ## Team
    ## Blog
    ## Testimonial
    ## Pricing Table
    ## Brand
    ## Contact
    ## Newslatter
    ## Fotter

  # Widgets
    ## Search
    ## Dt Custom Menu
    ## Dt Download Menu
    ## Dt Tags
    ## Dt Resent Post
    ## Dt Archive
  --------------------------------------------------------------*/
@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toRightFromLeft {
  49% {
    -moz-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

/*--------------------------------------------------------------
  # Base rules
  --------------------------------------------------------------*/
html {
  font-size: 14px;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #6d6c6c;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

p {
  color: #6d6c6c;
  font-size: 14px;
  font-size: 1em;
  line-height: 26px;
  line-height: 1.85714em;
}

.section-title {
  text-transform: uppercase;
  color: #111;
  margin-top: 0;
  position: relative;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}

.section-title:after {
  position: absolute;
  content: ' ';
  background: #33CEFF;
  width: 70px;
  height: 3px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.caption {
  color: #000;
  margin-bottom: 40px;
  line-height: 24px;
  line-height: 1.71429em;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

h1, h2, h3, h4, h5 {
  color: #2c3e50;
  margin-top: 0;
  font-family: 'PT Sans', sans-serif;
}

/*--------------------------------------------------------------
  # Elements
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Accessibility
  --------------------------------------------------------------*/
.says,
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/
a {
  text-decoration: none !important;
}

a:focus {
  text-decoration: none;
  color: #FFF;
}

.gp-bn-btn {
  position: relative;
  background-color: #33CEFF;
  padding: 12px 50px;
  text-decoration: none;
  display: inline-block;
  margin-right: 30px;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.gp-bn-btn:hover {
  background: #111;
  color: #FFF;
  text-decoration: none;
  border-color: #111;
}

.gp-bn-active {
  background-color: transparent;
  border: 1px solid #fff;
  margin-right: 0;
  padding: 11px 50px;
}

.gp-bn-active:hover {
  border-color: transparent;
}

.gp-btn {
  color: #000;
  padding: 10px 15px;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid #33CEFF;
  display: inline-block;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
}

.gp-bn-btn-2 {
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Primary Menu
  --------------------------------------------------------------*/
#header {
  background: rgba(0, 0, 0, 0.2);
  height: 100px;
  transition: all 0.5s ease-in-out;
  z-index: 600;
}

#header.navbar-bg {
  background: #ffffff;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.2);
  top: 0;
  transition: all .5s ease-in-out;
  height: 70px;
}

#header.navbar-bg .navbar-header {
  height: auto;
}

#header.navbar-bg .navbar-header .navbar-brand {
  padding: 12px 0 !important;
  width: 150px !important;
}

#header.navbar-bg .navbar-header .navbar-brand img {
  width: 100%;
}

#header.navbar-bg .navbar-header .navbar-brand.main-logo {
  display: none;
}

#header.navbar-bg .navbar-header .navbar-brand.fixed-logo {
  display: block !important;
}

#header.navbar-bg .navbar-nav {
  padding: 15px 0 !important;
  transition: all .3s ease-in-out;
}

#header.navbar-bg .navbar-nav li.gp-search:before {
  background: #111 !important;
}

#header.navbar-bg .navbar-nav li a {
  color: #000;
}

#header.navbar-bg .navbar-nav li a:hover {
  color: #33CEFF !important;
}

#header.navbar-bg .navbar-nav li .child-menu {
  top: 28px !important;
}

#header.navbar-bg .navbar-nav li .child-menu li {
  padding: 0;
}

#header.navbar-bg .navbar-nav li .child-menu li a {
  padding: 10px;
}

#header.navbar-bg .navbar-nav li .child-menu li a:hover {
  color: #FFF !important;
}

#header .navbar {
  margin-bottom: 0;
  height: auto;
  border: none;
}

#header .navbar-header {
  transition: all 0.3s ease-in-out;
}

#header .navbar-header .navbar-brand {
  padding: 20px 0;
  height: auto;
  transition: all 0.3s ease-in-out;
  width: 190px;
}

#header .navbar-header .navbar-brand img {
  width: 100%;
}

#header .navbar-header .navbar-brand.fixed-logo {
  display: none;
}

#header .navbar-nav {
  padding: 29px 0;
  transition: all 0.3s ease-in-out;
}

#header .navbar-nav li {
  padding: 0px 10px;
}

#header .navbar-nav li a {
  color: #FFF;
  text-transform: uppercase;
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-size: 14px;
  font-size: 1em;
  font-weight: 600;
}

#header .navbar-nav li a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: #33CEFF;
  left: 50%;
  right: 50%;
  bottom: 0;
  transition: all 0.5s ease-in-out;
}

#header .navbar-nav li a:hover, #header .navbar-nav li a:focus {
  background-color: transparent;
  color: #33CEFF;
}

#header .navbar-nav li a:hover:after, #header .navbar-nav li a:focus:after {
  left: 0;
  right: 0;
  width: 100%;
}

#header .navbar-nav li.active a {
  color: #33CEFF !important;
}

#header .navbar-nav li.active a:after {
  left: 0;
  right: 0;
  width: 100%;
}

#header .navbar-nav li.gp-search {
  position: relative;
  padding-left: 30px;
}

#header .navbar-nav li.gp-search a:after {
  display: none;
}

#header .navbar-nav li.gp-search:before {
  background: #FFF;
  height: 14px;
  width: 2px;
  content: '';
  position: absolute;
  left: 10px;
  top: 12px;
}

#header .navbar-nav li .child-menu {
  left: 0px;
  position: absolute;
  top: 102%;
  width: 200px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(50px);
  background: none 0px 0px repeat scroll #05263c;
  list-style: none outside none;
  margin: 0px;
  padding: 0px;
  transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

#header .navbar-nav li .child-menu li {
  padding: 10px;
  border-bottom: 1px solid #4e4e4e;
  transition: all 0.3s ease-in-out;
}

#header .navbar-nav li .child-menu li a {
  display: block;
  padding: 0;
  color: #FFF !important;
  text-transform: capitalize;
}

#header .navbar-nav li .child-menu li a:after {
  display: none;
}

#header .navbar-nav li .child-menu li:last-child {
  border-bottom: none;
}

#header .navbar-nav li .child-menu li:hover {
  background-color: #33CEFF;
}

#header .navbar-nav li .child-menu li:hover a {
  color: #FFF !important;
  display: block;
  text-decoration: none !important;
}

#header .navbar-nav li:hover .child-menu {
  transform: translateY(28px);
  visibility: visible;
  transition: all 0.5s ease-out;
  opacity: 1;
  z-index: 9999;
}

#header.headroom--pinned:not(.headroom--top) {
  background-color: #33CEFF;
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.2);
  top: 0;
  transition: all .5s ease-in-out;
}

#header.headroom--pinned:not(.headroom--top) .navbar-header {
  height: auto;
}

#header.headroom--pinned:not(.headroom--top) .navbar-header .navbar-brand {
  padding: 12px 0 !important;
  width: 150px !important;
}

#header.headroom--pinned:not(.headroom--top) .navbar-header .navbar-brand img {
  width: 100%;
}

#header.headroom--pinned:not(.headroom--top) .navbar-header .navbar-brand.main-logo {
  display: none;
}

#header.headroom--pinned:not(.headroom--top) .navbar-header .navbar-brand.fixed-logo {
  display: block !important;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav {
  padding: 15px 0 !important;
  transition: all .3s ease-in-out;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav li.gp-search:before {
  background: #111 !important;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav li a {
  color: #000;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav li a:hover {
  color: #33CEFF !important;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav li .child-menu {
  top: 28px !important;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav li .child-menu li {
  padding: 0;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav li .child-menu li a {
  padding: 10px;
}

#header.headroom--pinned:not(.headroom--top) .navbar-nav li .child-menu li a:hover {
  color: #FFF !important;
}

.headroom--pinned {
  transition: all 0.3s ease-in-out;
}

/*--------------------------------------------------------------
  # Top Menu
  --------------------------------------------------------------*/
.cd-auto-hide-header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
}

.cd-auto-hide-header::after {
  clear: both;
  content: "";
  display: block;
}

.cd-auto-hide-header.is-hidden {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.cd-auto-hide-header .logo,
.cd-auto-hide-header .nav-trigger {
  position: absolute;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cd-auto-hide-header .logo {
  left: 5%;
}

.cd-auto-hide-header .logo a, .cd-auto-hide-header .logo img {
  display: block;
}

.cd-auto-hide-header .nav-trigger {
  /* vertically align its content */
  display: table;
  height: 100%;
  padding: 0 1em;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #25283D;
  font-weight: bold;
  right: 0;
  border-left: 1px solid #f2f2f2;
}

.cd-auto-hide-header .nav-trigger span {
  /* vertically align inside parent element */
  display: table-cell;
  vertical-align: middle;
}

.cd-auto-hide-header .nav-trigger em, .cd-auto-hide-header .nav-trigger em::after, .cd-auto-hide-header .nav-trigger em::before {
  /* this is the menu icon */
  display: block;
  position: relative;
  height: 2px;
  width: 22px;
  background-color: #25283D;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cd-auto-hide-header .nav-trigger em {
  /* this is the menu central line */
  margin: 6px auto 14px;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
}

.cd-auto-hide-header .nav-trigger em::before, .cd-auto-hide-header .nav-trigger em::after {
  position: absolute;
  content: '';
  left: 0;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.cd-auto-hide-header .nav-trigger em::before {
  /* this is the menu icon top line */
  -webkit-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  transform: translateY(-6px);
}

.cd-auto-hide-header .nav-trigger em::after {
  /* this is the menu icon bottom line */
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
}

@media only screen and (min-width: 1024px) {
  .cd-auto-hide-header .nav-trigger {
    display: none;
  }
}

.cd-auto-hide-header.nav-open .nav-trigger em {
  /* transform menu icon into a 'X' icon */
  background-color: rgba(255, 255, 255, 0);
}

.cd-auto-hide-header.nav-open .nav-trigger em::before {
  /* rotate top line */
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.cd-auto-hide-header.nav-open .nav-trigger em::after {
  /* rotate bottom line */
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cd-primary-nav {
  display: inline-block;
  float: right;
  height: 100%;
  padding-right: 5%;
}

.cd-primary-nav > ul {
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  display: none;
  box-shadow: 0 14px 20px rgba(0, 0, 0, 0.2);
}

.cd-primary-nav > ul a {
  /* target primary-nav links */
  display: block;
  height: 50px;
  line-height: 50px;
  padding-left: 5%;
  color: #25283D;
  font-size: 1.8rem;
  border-top: 1px solid #f2f2f2;
}

.cd-primary-nav > ul a:hover, .cd-primary-nav > ul a.active {
  color: #8F3985;
}

@media only screen and (min-width: 1024px) {
  .cd-primary-nav {
    /* vertically align its content */
    display: table;
  }
  .cd-primary-nav > ul {
    /* vertically align inside parent element */
    display: table-cell;
    vertical-align: middle;
    /* reset mobile style */
    position: relative;
    width: auto;
    top: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .cd-primary-nav > ul::after {
    clear: both;
    content: "";
    display: block;
  }
  .cd-primary-nav > ul li {
    display: inline-block;
    float: left;
    margin-right: 1.5em;
  }
  .cd-primary-nav > ul li:last-of-type {
    margin-right: 0;
  }
  .cd-primary-nav > ul a {
    /* reset mobile style */
    height: auto;
    line-height: normal;
    padding: 0;
    border: none;
  }
}

.nav-open .cd-primary-nav ul,
.cd-primary-nav ul:target {
  /*
    show primary nav - mobile only
    :target is used to show navigation on no-js devices
  */
  display: block;
}

@media only screen and (min-width: 1024px) {
  .nav-open .cd-primary-nav ul,
  .cd-primary-nav ul:target {
    display: table-cell;
  }
}

/* --------------------------------

2. Auto-Hiding Navigation - with Sub Nav

-------------------------------- */
.cd-secondary-nav {
  position: relative;
  z-index: 1;
  clear: both;
  width: 100%;
  height: 50px;
  background-color: #25283D;
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
}

.cd-secondary-nav::after {
  /* gradient on the right - to indicate it's possible to scroll */
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 35px;
  background: transparent;
  background: -webkit-linear-gradient(right, #25283D, rgba(37, 40, 61, 0));
  background: linear-gradient(to left, #25283D, rgba(37, 40, 61, 0));
  pointer-events: none;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.cd-secondary-nav.nav-end::after {
  opacity: 0;
}

.cd-secondary-nav ul, .cd-secondary-nav li, .cd-secondary-nav a {
  height: 100%;
}

.cd-secondary-nav ul {
  /* enables a flex context for all its direct children */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 5%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.cd-secondary-nav ul::after {
  clear: both;
  content: "";
  display: block;
}

.cd-secondary-nav li {
  display: inline-block;
  float: left;
  /* do not shrink - elements float on the right of the element */
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.cd-secondary-nav li:last-of-type {
  padding-right: 20px;
}

.cd-secondary-nav a {
  display: block;
  color: #ffffff;
  opacity: .6;
  line-height: 50px;
  padding: 0 1em;
}

.cd-secondary-nav a:hover, .cd-secondary-nav a.active {
  opacity: 1;
}

@media only screen and (min-width: 1024px) {
  .cd-secondary-nav {
    height: 70px;
    overflow: visible;
  }
  .cd-secondary-nav ul {
    /* reset mobile style */
    display: block;
    text-align: center;
  }
  .cd-secondary-nav li {
    /* reset mobile style */
    float: none;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .cd-secondary-nav a {
    line-height: 70px;
  }
  .cd-secondary-nav a.active {
    box-shadow: inset 0 -3px #8F3985;
  }
}

/* --------------------------------

3. Auto-Hiding Navigation - with Sub Nav + Hero Image

-------------------------------- */
.cd-secondary-nav.fixed {
  position: fixed;
  top: 60px;
}

.cd-secondary-nav.slide-up {
  -webkit-transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  transform: translateY(-60px);
}

@media only screen and (min-width: 1024px) {
  .cd-secondary-nav.fixed {
    top: 80px;
    /* fixes a bug where nav and subnab move with a slight delay */
    box-shadow: 0 -6px 0 #25283D;
  }
  .cd-secondary-nav.slide-up {
    -webkit-transform: translateY(-80px);
    -ms-transform: translateY(-80px);
    transform: translateY(-80px);
  }
}

/* --------------------------------

Main content

-------------------------------- */
.cd-main-content {
  padding: 60px 5% 2em;
  overflow: hidden;
}

.cd-main-content.sub-nav {
  /* to be used if there is sub nav */
  padding-top: 110px;
}

.cd-main-content.sub-nav-hero {
  /* to be used if there is hero image + subnav */
  padding-top: 0;
}

.cd-main-content.sub-nav-hero.secondary-nav-fixed {
  margin-top: 50px;
}

.cd-main-content p {
  max-width: 1024px;
  line-height: 1.6;
  margin: 2em auto;
  font-family: "David Libre", serif;
  color: #a5a8a9;
}

@media only screen and (min-width: 1024px) {
  .cd-main-content {
    padding-top: 80px;
  }
  .cd-main-content.sub-nav {
    padding-top: 150px;
  }
  .cd-main-content.sub-nav-hero.secondary-nav-fixed {
    margin-top: 70px;
  }
  .cd-main-content p {
    font-size: 2.4rem;
  }
}

/*
  adjust the positioning of in-page links
  http://nicolasgallagher.com/jump-links-and-viewport-positioning/
*/
.cd-main-content.sub-nav :target::before,
.cd-main-content.sub-nav-hero :target::before {
  display: block;
  content: "";
  margin-top: -50px;
  height: 50px;
  visibility: hidden;
}

@media only screen and (min-width: 1024px) {
  .cd-main-content.sub-nav :target::before,
  .cd-main-content.sub-nav-hero :target::before {
    margin-top: -70px;
    height: 70px;
  }
}

/* --------------------------------

Intro Section

-------------------------------- */
.cd-hero {
  /* vertically align its content */
  display: table;
  width: 100%;
  margin-top: 60px;
  height: 300px;
  background: url(../img/cd-hero-background.jpg) no-repeat center center;
  background-size: cover;
}

.cd-hero .cd-hero-content {
  /* vertically align inside parent element */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .cd-hero {
    height: 400px;
  }
}

@media only screen and (min-width: 1024px) {
  .cd-hero {
    height: 600px;
    margin-top: 80px;
  }
}

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Loading
  --------------------------------------------------------------*/
.loading {
  display: block;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111;
  opacity: 1;
  -webkit-transition: all 1.5s 2s ease-in-out;
  -moz-transition: all 1.5s 2s ease-in-out;
  -ms-transition: all 1.5s 2s ease-in-out;
  -o-transition: all 1.5s 2s ease-in-out;
  transition: all 1.5s 2s ease-in-out;
}

.loading .loading-wrapper {
  position: relative;
  left: calc(50% - 25px);
  top: calc(50% - 20px);
  width: 50px;
  height: 40px;
  -webkit-transition: all 2s 1s ease-in-out;
  -moz-transition: all 2s 1s ease-in-out;
  -ms-transition: all 2s 1s ease-in-out;
  -o-transition: all 2s 1s ease-in-out;
  transition: all 2s 1s ease-in-out;
}

.loading .loading-wrapper .rec {
  display: inline-block;
  height: 100%;
  width: 6px;
  background: #fff;
  -webkit-animation: loading 1.2s infinite ease-in-out;
  -moz-animation: loading 1.2s infinite ease-in-out;
  -ms-animation: loading 1.2s infinite ease-in-out;
  -o-animation: loading 1.2s infinite ease-in-out;
  animation: loading 1.2s infinite ease-in-out;
}

.loading .loading-wrapper .r2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loading .loading-wrapper .r3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loading .loading-wrapper .r4 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

.loading .loading-wrapper .r5 {
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s;
}

@-webkit-keyframes loading {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-moz-keyframes loading {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-ms-keyframes loading {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-o-keyframes loading {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes loading {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.loading.loaded {
  visibility: hidden;
  opacity: 0;
}

.loaded .loading-wrapper {
  opacity: 0;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
/* Back To Top */
.return-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #33CEFF;
  width: 42px;
  height: 42px;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  z-index: 1000;
}

.return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 11px;
  top: 9px;
  font-size: 19px;
  transition: all 0.3s ease;
}

.return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

.return-to-top:hover i {
  color: #fff;
  top: 5px;
}

.back-top {
  display: block;
}

.header_wrapper {
  width: 94%;
  margin: 0 auto;
  max-width: 1140px;
  position: relative;
}

.dialog {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  pointer-events: none;
  z-index: 9999;
}

.dialog, .dialog__overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dialog--close .dialog__overlay {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.dialog--open .dialog__overlay {
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.dialog--open .dialog__overlay {
  opacity: 1;
  pointer-events: auto;
}

.dialog__overlay {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-backface-visibility: hidden;
}

.dialog.dialog--open .dialog__content {
  opacity: 1;
}

.dialog--open .dialog__content {
  pointer-events: auto;
}

.dialog__content {
  padding: 0;
  background: transparent;
}

.dialog__content {
  width: 80%;
  max-width: 520px;
  min-width: 290px;
  background: #fff;
  padding: 50px;
  text-align: center;
  position: relative;
  z-index: 5;
  opacity: 0;
}

.dialog--open .dialog-inner {
  opacity: 1;
  transition: opacity 0.3s;
}

.dialog-inner {
  opacity: 0;
  background: #fff;
}

.dialog.dialog--open h2 {
  -webkit-animation: anim-elem-1 0.7s ease-out both;
  -o-animation: anim-elem-1 0.7s ease-out both;
  animation: anim-elem-1 0.7s ease-out both;
}

.dialog h2 {
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  padding: 0 0 15px;
  text-transform: uppercase;
}

#search_popup_wrapper form {
  margin: 10px 0;
  position: relative;
}

.search-form {
  position: relative;
  width: 100%;
}

.search-form .search-field {
  width: 100%;
}

input[type="search"] {
  padding-left: 20px;
  padding-right: 35px;
  height: 45px;
  line-height: 1;
  color: #aaa;
  border: 1px solid transparent;
  transition: all 0.3s;
  box-sizing: border-box;
  background-color: #f7f7f7;
  vertical-align: top;
  color: #111;
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.3s ease-in-out;
}

input[type="search"]:hover {
  border-color: #33CEFF;
}

.search-form .search-submit {
  position: absolute;
  width: 45px;
  height: 45px;
  right: 0;
  top: 0;
  padding: 0;
  font-size: 16px;
  background-color: transparent;
  color: #bababa;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.search-form .search-submit:hover {
  color: #33CEFF;
}

.dialog.dialog--open a {
  position: absolute;
  border: none;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  line-height: 35px !important;
  background-color: #eee;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.dialog.dialog--open a:hover {
  color: #33CEFF;
}

.navbar-toggle .icon-bar {
  background: #FFF;
}

/* Mobile Menu */
#mobile-header {
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 8px 10px;
  height: 50px;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 10000;
}

#mobile-header .main-logo {
  width: 100px;
  display: inline-block;
}

#mobile-header .main-logo img {
  width: 100%;
}

.menu-container {
  float: right;
}

.cbp-spmenu-right {
  right: -270px;
}

.cbp-spmenu-vertical a {
  border-bottom: none;
}

.cbp-spmenu-vertical a:hover {
  background: none;
}

.cbp-spmenu-push-toleft:before {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 10000;
}

.cbp-spmenu-push-toleft {
  left: -270px;
}

.dt-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dt-social-link li {
  display: inline-block;
  margin-right: 8px;
  text-decoration: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #33CEFF;
  transition: all 0.3s ease-in-out;
}

.dt-social-link li:last-child {
  padding: 0;
}

.dt-social-link li a {
  color: #33CEFF;
  transition: all 0.3s ease-in-out;
  display: block;
  transition: all 0.3s ease-in-out;
  padding: 0;
}

.dt-social-link li a i {
  font-size: 12px;
  font-size: 0.85714em;
  line-height: 28px;
  line-height: 2em;
}

.dt-social-link li:hover {
  background: #33CEFF !important;
}

.dt-social-link li:hover a {
  color: #FFF;
}

#mobile-wrapper {
  width: 270px;
  background: #222533;
  position: fixed;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  height: 100vh;
}

.mobile_menu {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.mobile_menu li {
  border-bottom: 1px solid #06191f;
  border-top: 1px solid #313131;
}

.mobile_menu li:first-child {
  border-top: none;
}

.mobile_menu li:last-child {
  border-bottom: none;
}

.mobile_menu li a {
  display: block;
  color: #FFF;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.menu-item-has-children .sub-menu {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-item-has-children .sub-menu li {
  background: #1d1d1d;
}

.menu-item-has-children.children-menu-visible .sub-menu {
  display: block;
  transition: all 0.3s ease-in-out;
}

.mobile_menu_search {
  padding: 0 20px;
  margin-bottom: 30px;
}

.mobile_menu_search #search {
  border: 1px solid #ddd;
  position: relative;
}

.mobile_menu_search #search input[type="text"] {
  border: none;
  outline: none;
  background: transparent;
  padding: 7px 10px;
  width: 85%;
  color: #FFF;
}

.mobile_menu_search #searchsubmit {
  background-color: transparent;
  border: none;
}

.mobile_menu_search #searchsubmit:focus, .mobile_menu_search #searchsubmit:hover {
  outline: none;
}

.mobile_menu_search #searchsubmit:hover {
  color: #33CEFF;
}

.menu-toggle {
  position: relative;
  width: 30px;
  height: 23px;
  float: right;
  margin: 6px;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-toggle > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 1px;
  background-color: #33CEFF;
  transition: all 0.5s ease-in-out;
}

.menu-toggle > div:nth-child(2) {
  top: 50%;
  margin-top: -1px;
}

.menu-toggle > div:nth-child(3) {
  top: auto;
  bottom: 0;
}

.menu-toggle.active > div:nth-child(1) {
  transform: translateY(10px) rotate(135deg);
}

.menu-toggle.active > div:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active > div:nth-child(3) {
  transform: translateY(-12px) rotate(-135deg);
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
/* Banner */
.swiper-banner {
  position: relative;
  overflow: hidden;
}

.swiper-banner .swiper-slide .slider-caption-one, .swiper-banner .swiper-slide .slider-caption-two, .swiper-banner .swiper-slide .slider-caption-three {
  opacity: 0;
  transition-delay: .7s;
}

.swiper-banner .swiper-slide-active .slider-caption-one, .swiper-banner .swiper-slide-active .slider-caption-two, .swiper-banner .swiper-slide-active .slider-caption-three {
  opacity: 1;
}

.swiper-banner .swiper-slide > img {
  width: 100%;
  height: auto;
}

.swiper-banner .swiper-slide .slider-caption h3 {
  font-size: 20px;
  font-size: 1.42857em;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.6em;
  margin-top: 0;
  text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.swiper-banner .swiper-slide .slider-caption h2 {
  font-size: 50px;
  font-size: 3.57143em;
  color: #FFF;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 40px;
}

.swiper-banner .swiper-slide .slider-caption p {
  color: #FFF;
  line-height: 26px;
  line-height: 1.85714em;
  margin-bottom: 50px;
}

.banner-next, .banner-prev {
  top: 50%;
  position: absolute;
  z-index: 500;
  color: #FFF;
  background: rgba(0, 0, 0, 0.7);
  height: 50px;
  width: 50px;
  transition: all 0.3s ease;
  text-align: center;
  opacity: 0;
  overflow: hidden;
  cursor: pointer;
}

.banner-next .next-btn i, .banner-next .prev-btn i, .banner-prev .next-btn i, .banner-prev .prev-btn i {
  font-size: 40px;
  font-size: 2.85714em;
}

.banner-next:hover, .banner-prev:hover {
  background: #33CEFF;
}

.banner-next {
  right: 0%;
}

.swiper-pagination {
  text-align: right;
  right: 100px;
  left: auto !important;
}

.swiper-pagination-bullet {
  width: 3px;
  height: 20px;
  border-radius: 0;
  background: #FFF;
  opacity: 1;
  margin: 0 2px !important;
}

.swiper-pagination-bullet-active {
  background: #33CEFF;
  height: 25px;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.slider-caption-one {
  text-align: center;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 100%;
  position: absolute;
}

.slider-caption-one h2 {
  font-size: 70px;
  font-size: 5em;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  position: relative;
  padding: 15px 0;
  display: block;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.slider-caption-one h2:before, .slider-caption-one h2:after {
  position: absolute;
  content: '';
  background: #33CEFF;
  width: 300px;
  height: 3px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.slider-caption-one h2:before {
  top: 0;
}

.slider-caption-one h2:after {
  bottom: 0;
}

.slider-caption-one h3 {
  font-size: 22px;
  color: #FFF;
  margin-bottom: 50px;
  letter-spacing: 1px;
}

.slider-caption-two {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
}

.slider-caption-two h4 {
  color: #33CEFF;
  font-size: 22px;
  font-size: 1.57143em;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.slider-caption-two h2 {
  font-size: 40px;
  font-size: 2.85714em;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.slider-caption-two p {
  color: #FFF;
  font-size: 16px;
  font-size: 1.14286em;
  margin-bottom: 30px;
}

.slider-caption-three {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
}

.slider-caption-three h3 {
  color: #fff;
  font-weight: 600;
  font-size: 32px;
  font-size: 2.28571em;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-weight: 600;
}

.slider-caption-three h4 {
  color: #FFF;
  font-size: 22px;
  font-size: 1.57143em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.slider-caption-three h2 {
  font-size: 50px;
  font-size: 3.57143em;
  color: #33CEFF;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.slider-caption-three p {
  color: #ddd;
  font-size: 15px;
  font-size: 1.07143em;
  margin-bottom: 30px;
}

.switcher {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  display: block;
  animation: move 2.5s infinite;
}

.switcher i {
  color: #FFF;
  font-size: 30px;
}

.switcher:hover {
  text-decoration: none;
}

.swiper-banner:hover .banner-next, .swiper-banner:hover .banner-prev {
  opacity: 1;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

.banner-two .banner-next, .banner-two .banner-prev {
  height: 100px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
}

.banner-two .banner-next i, .banner-two .banner-prev i {
  line-height: 100px;
}

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
#about {
  text-align: center;
  padding: 70px 0;
  background: #f5f5f5;
  overflow: hidden;
}

#about .caption {
  margin-bottom: 70px;
  color: #111;
}

.about-box {
  border-right: 10px;
  background: #FFF;
  padding: 60px 20px 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  text-align: left;
  position: relative;
}

.about-box .icon-box {
  height: 70px;
  width: 70px;
  background: #FFF;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  top: -35px;
  left: 20px;
  border: 1px solid #ddd;
}

.about-box .icon-box i {
  font-size: 30px;
  font-size: 2.14286em;
  line-height: 76px;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.about-box .about-title {
  font-size: 20px;
  font-size: 1.42857em;
  color: #111;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.about-box p {
  margin-bottom: 0;
  color: #111;
  font-size: 15px;
  font-size: 1.07143em;
}

.about-box:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.about-box:hover .icon-box {
  background: #33CEFF;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 1px;
}

.about-box:hover .icon-box i {
  color: #FFF;
}

.about-box:hover .about-title {
  color: #33CEFF;
}

/* Aboute Two */
.about-two {
  padding: 80px 0;
  text-align: center;
}

.about-two .about-box {
  text-align: center;
  padding: 20px;
}

.about-two .about-box i {
  font-size: 55px;
  font-size: 3.92857em;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.about-two .about-box:hover i {
  color: #33CEFF;
}

/*--------------------------------------------------------------
  ##  Service
  --------------------------------------------------------------*/
#service {
  text-align: center;
  padding: 70px 0;
  position: relative;
  background: #f5f5f5;
  overflow: hidden;
}

.service-item {
  text-align: center;
}

.service-item .service {
  padding: 20px;
  transition: all 0.5s ease;
  cursor: pointer;
  text-align: center;
}

.service-item .service .icon-box {
  margin: 0 auto;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  background: #FFF;
  position: relative;
}

.service-item .service .icon-box i {
  font-size: 35px;
  font-size: 2.5em;
  color: #111;
  position: relative;
  z-index: 10;
  line-height: 100px;
}

.service-item .service .icon-box .icon-overlay {
  position: absolute;
  height: 30%;
  width: 30%;
  background: #33CEFF;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  margin: auto;
}

.service-item .service .service_content .title {
  color: #111;
  font-size: 18px;
  font-size: 1.28571em;
  margin-top: 0;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.service-item .service .service_content p {
  color: #111;
  margin-bottom: 0;
}

.service-item .service:hover .icon-box {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 1px;
}

.service-item .service:hover .icon-box i {
  color: #FFF;
}

.service-item .service:hover .icon-box .icon-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.service-item .service:hover .service_content .title {
  color: #33CEFF;
}

/* Service Two */
.service-two {
  padding: 100px 0;
  text-align: center;
}

.service-two .service {
  margin: 1px;
  border: 1px solid #a2a2a2;
  height: 260px;
  padding: 55px 0;
}

.service-two .service i {
  font-size: 70px;
  font-size: 5em;
  color: #33CEFF;
  display: block;
  margin-bottom: 20px;
}

.service-two .service .overlay {
  background: #33CEFF;
  padding: 50px 0;
  opacity: 0;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.service-two .service .overlay h3 {
  color: #FFF;
  font-weight: 700;
  margin-bottom: 30px;
}

.service-two .service .overlay p {
  color: #111;
}

.service-two .service:hover .overlay {
  transform: scaleX(1);
  opacity: 1;
}

/*--------------------------------------------------------------
  ##  Portfolio
  --------------------------------------------------------------*/
/* Portfolio */
.portfolio {
  padding-top: 60px;
  padding-bottom: 80px;
  text-align: center;
  overflow: hidden;
}

.portfolio .portfolioFilter {
  margin-bottom: 50px;
  padding: 0;
  list-style: none;
}

.portfolio .portfolioFilter li {
  display: inline-block;
  margin-right: 10px;
}

.portfolio .portfolioFilter li a {
  text-decoration: none;
  color: #111;
  font-size: 14px;
  padding: 6px 15px;
  border: 1px solid #eee;
  transition: all 0.3s ease-in-out;
  background: #eee;
}

.portfolio .portfolioFilter li a.current, .portfolio .portfolioFilter li a:hover {
  background-color: #33CEFF;
  color: #FFF;
  border-color: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.portfolio_container .portfolio-item {
  position: relative;
  overflow: hidden;
}

.portfolio_container .portfolio-item img {
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.portfolio_container .portfolio-item .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(53, 152, 219, 0.8);
  text-align: left;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.portfolio_container .portfolio-item .overlay:before, .portfolio_container .portfolio-item .overlay:after {
  content: "";
  opacity: 0;
  position: absolute;
  left: 15px;
  bottom: 15px;
  right: 15px;
  top: 15px;
  z-index: 1;
  transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}

.portfolio_container .portfolio-item .overlay:before {
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  transform: scale(0, 1);
}

.portfolio_container .portfolio-item .overlay:after {
  border-left: 1px solid #FFF;
  border-right: 1px solid #FFF;
  transform: scale(1, 0);
}

.portfolio_container .portfolio-item .overlay .work-caption {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.portfolio_container .portfolio-item .overlay .work-caption .view {
  color: #FFF;
  display: inline-block;
  color: #FFF;
  transition: all .5s ease-in-out;
  font-size: 20px;
  font-size: 1.42857em;
  height: 40px;
  width: 40px;
  padding: 5px;
  border: 1px solid #FFF;
  text-align: center;
  line-height: 20px;
  line-height: 1.42857em;
  border-radius: 50%;
  margin-bottom: 100px;
}

.portfolio_container .portfolio-item .overlay .work-caption .view:first-child {
  margin-right: 10px;
}

.portfolio_container .portfolio-item .overlay .work-caption .view:hover {
  background: #000;
  border-color: transparent;
}

.portfolio_container .portfolio-item .overlay .work-caption h2 {
  margin-top: 0;
  font-size: 22px;
  font-size: 1.57143em;
  transition: all 0.5s ease-in-out;
  transform: translateY(70px) scale(0.95);
  text-transform: uppercase;
}

.portfolio_container .portfolio-item .overlay .work-caption h2 a {
  color: #FFF;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.portfolio_container .portfolio-item .overlay .work-caption h2 a:hover {
  color: #111;
}

.portfolio_container .portfolio-item .overlay .work-caption h3 {
  font-size: 16px;
  font-size: 1.14286em;
  margin: 0;
  transition: all 0.7s ease-in-out;
  transform: translateY(60px) scale(0.95);
}

.portfolio_container .portfolio-item .overlay .work-caption h3 a {
  color: #FFF;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.portfolio_container .portfolio-item .overlay .work-caption h3 a:hover {
  color: #111;
}

.portfolio_container .portfolio-item:hover img {
  transform: scale(1.3);
}

.portfolio_container .portfolio-item:hover .overlay {
  transform: scale(1);
}

.portfolio_container .portfolio-item:hover .overlay:before {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.4s ease 0s, transform 0.45s ease 0.2s;
  transform: scale(1);
}

.portfolio_container .portfolio-item:hover .overlay:after {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.4s ease 0s, transform 0.45s ease 0.2s;
  transform: scale(1);
}

.portfolio_container .portfolio-item:hover .overlay .work-caption .view {
  margin-bottom: 30px;
}

.portfolio_container .portfolio-item:hover .overlay .work-caption h2 {
  transform: translateX(0px) scale(1);
}

.portfolio_container .portfolio-item:hover .overlay .work-caption h3 {
  transform: translateX(0) scale(1);
}

/* Portfolio Two */
.portfolio-bg {
  background: #f5f5f5;
}

#portfolio-two .grid-item {
  width: 33%;
  padding: 10px;
  float: left;
}

.portfolio_container .mix {
  display: none;
}

#portfolio-two .portfolio-item {
  margin: 5px;
}

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
.countup {
  padding: 100px 0;
  position: relative;
  background-size: cover;
  overflow: hidden;
  background-size: cover;
}

.counter-box {
  border: 3px solid #33CEFF;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.5);
}

.counter-box .count i {
  font-size: 50px;
  color: #33CEFF;
  margin-bottom: 25px;
}

.counter-box .count h2 {
  font-size: 50px;
  font-size: 3.57143em;
  color: #FFF;
  font-weight: 700;
}

.counter-box .count p {
  color: #FFF;
  font-size: 16px;
  font-size: 1.14286em;
  font-weight: 300;
}

/* CountUp Two */
.countup-two {
  padding: 100px 0;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.countup-two .overlay {
  background: rgba(53, 152, 219, 0.5);
}

.countup-two .counter-box {
  border: none;
  background: rgba(0, 0, 0, 0.8);
}

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
#gp-team {
  padding: 70px 0;
  text-align: center;
  background: #f5f5f5;
  overflow: hidden;
}

#gp-team .caption {
  margin-bottom: 40px;
}

.team_member {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  border: 3px solid #33CEFF;
}

.team_member .team_thumb img {
  width: 100%;
}

.team_member .team_intro {
  position: absolute;
  bottom: -75%;
  left: 0;
  background-color: rgba(53, 152, 219, 0.9);
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  transition: all 0.6s cubic-bezier(0.42, -0.4, 0.57, 1.53);
}

.team_member .team_intro h4 {
  text-transform: uppercase;
  color: #111;
  margin-top: 0;
  font-size: 22px;
  font-size: 1.57143em;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}

.team_member .team_intro h5 {
  font-size: 18px;
  font-size: 1.28571em;
  color: #111;
  font-weight: 700;
}

.team_member .team_intro span {
  color: #FFF;
  display: block;
  margin-bottom: 50px;
}

.team_member .team_intro p {
  color: #fff;
}

.team_member .team_intro .team_link {
  padding: 0;
  margin: 0;
  list-style: none;
}

.team_member .team_intro .team_link li {
  display: inline-block;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  border: 1px solid #FFF;
  text-align: center;
  background: transparent;
  transition: all 0.3s ease-in-out;
}

.team_member .team_intro .team_link li a i {
  font-size: 15px;
  font-size: 1.07143em;
  line-height: 28px;
  line-height: 2em;
  color: #FFF;
}

.team_member:hover .team_intro {
  bottom: 0;
  border-radius: 0;
}

.team_member:hover .team_intro h4 {
  margin-top: 40px;
}

.team_member:hover .team_intro .team_link li:hover {
  background: #111;
  border-color: transparent;
}

.team_member:hover .team_intro .team_link li:hover a i {
  color: #33CEFF;
}

/* Team Two */
.team-two .owl-carousel {
  position: relative;
}

.team-two .owl-carousel .owl-nav .owl-next, .team-two .owl-carousel .owl-nav .owl-prev {
  font-family: "dt-icons" !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.team-two .owl-carousel .owl-nav .owl-next:before, .team-two .owl-carousel .owl-nav .owl-next:after, .team-two .owl-carousel .owl-nav .owl-prev:before, .team-two .owl-carousel .owl-nav .owl-prev:after {
  font-size: 70px;
  font-size: 5em;
  color: #111;
}

.team-two .owl-carousel .owl-nav .owl-next:hover, .team-two .owl-carousel .owl-nav .owl-prev:hover {
  background-color: transparent;
}

.team-two .owl-carousel .owl-nav .owl-prev {
  left: -100px;
}

.team-two .owl-carousel .owl-nav .owl-prev:before {
  content: "\e03f";
}

.team-two .owl-carousel .owl-nav .owl-next {
  right: -100px;
}

.team-two .owl-carousel .owl-nav .owl-next:after {
  content: "\e04b";
}

#team-slider .team_member {
  padding: 0;
  border: 2px solid #33CEFF;
}

#team-slider .team_member .team_thumb img {
  transition: all 0.3s ease-in-out;
}

#team-slider .team_member .team_intro {
  text-align: center !important;
  bottom: 0;
  border-radius: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

#team-slider .team_member .team_intro h4 {
  margin-top: 150px;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
  letter-spacing: 1px;
}

#team-slider .team_member .team_intro h4:after {
  position: absolute;
  content: '';
  background: #333;
  height: 2px;
  width: 70px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#team-slider .team_member .team_intro p {
  margin-bottom: 20px;
}

#team-slider .team_member:hover .team_thumb img {
  transform: scale(1.1);
}

#team-slider .team_member:hover .team_intro {
  opacity: 1;
}

#team-slider .team_member:hover .team_intro h4 {
  margin-top: 100px;
}

/*--------------------------------------------------------------
  ##  Skills
  --------------------------------------------------------------*/
.our-skills {
  overflow: hidden;
  position: relative;
}

.our-skills .skills {
  display: flex;
  align-items: center;
}

.our-skills .skills-title {
  text-align: center;
}

.our-skills .skills-title h2 {
  color: #000;
  font-weight: 700;
  font-size: 30px;
  font-size: 2.14286em;
  margin-bottom: 20px;
}

.our-skills .skills-title p {
  color: #000;
  margin-bottom: 30px;
}

.our-skills p {
  color: #000;
  margin-bottom: 10px;
}

.our-skills .skills-thumb img {
  width: 100%;
}

.skills-wrapper {
  max-width: 550px;
  margin: 0 auto;
  top: 50%;
}

.skills-heading span {
  font-size: 16px;
  font-weight: 700;
  color: #111111;
}

.skills-heading p {
  color: #000;
  float: right;
  margin-bottom: 0;
}

progress {
  border: 0;
  height: 15px;
  width: 100%;
  margin-bottom: 10px;
}

progress::-webkit-progress-bar {
  background-color: #eaeaea;
}

progress::-webkit-progress-value {
  background-color: #33CEFF;
  height: 15px;
}

/* Skills Two */
.our-skills-two {
  padding: 150px 0;
  background-size: cover;
  position: relative;
}

.our-skills-two .overlay {
  background: rgba(0, 0, 0, 0.8);
}

.our-skills-two .skill-bar {
  padding-right: 100px;
}

.our-skills-two .skill-bar .skills-title h2 {
  color: #33CEFF;
  font-weight: 700;
  margin-bottom: 30px;
}

.our-skills-two .skill-bar .skills-heading span, .our-skills-two .skill-bar .skills-heading p {
  color: #FFF;
}

.our-skills-two progress::-webkit-progress-bar {
  padding: 1px;
  border: 1px solid #FFF;
  background-color: transparent;
  height: 9px;
}

.our-skills-two progress::-webkit-progress-value {
  height: 5px;
}

.skills-description h2 {
  color: #FFF;
  margin-top: 0;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
}

.skills-description h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #33CEFF;
  width: 50px;
  content: '';
  height: 4px;
}

.skills-description p {
  color: #ddd;
  margin-bottom: 45px;
}

.skills-description .gp-btn {
  color: #FFF;
  border-color: #FFF;
  border-radius: 0;
  background: #33CEFF;
  border-color: transparent;
}

.skills-description .gp-btn:hover {
  background-color: transparent;
  border-color: #FFF;
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
#blog {
  padding: 50px 0;
  text-align: center;
  overflow: hidden;
}

.blog_post {
  text-align: left;
  border: 1px solid #ecf0f1;
  padding: 15px;
}

.blog_post ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog_post ul li {
  display: inline-block;
}

.blog_post .blog-thumb {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.blog_post .blog-thumb img {
  width: 100%;
  transition: all 0.8s ease-in-out;
  overflow: hidden;
}

.blog_post .blog-thumb .blog-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 152, 219, 0.8);
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.blog_post .blog-thumb:hover img {
  transform: scale(1.2);
}

.blog_post .blog-thumb:hover .blog-overlay {
  opacity: 1;
}

.blog_post .blog_content .post_meta {
  margin-bottom: 10px;
}

.blog_post .blog_content .post_meta li {
  padding-right: 10px;
  position: relative;
}

.blog_post .blog_content .post_meta li:first-child:after {
  content: '';
  position: absolute;
  height: 12px;
  width: 2px;
  color: #DDD;
  background: #989898;
  right: 0;
  top: 4px;
}

.blog_post .blog_content .post_meta li:last-child {
  margin-left: 10px;
}

.blog_post .blog_content .post_meta li a {
  text-decoration: none;
  color: #989898;
}

.blog_post .blog_content .post_meta li a i {
  padding-right: 5px;
}

.blog_post .blog_content .blog_title {
  padding-bottom: 5px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 20px;
  font-size: 1.42857em;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  font-weight: 700;
}

.blog_post .blog_content .blog_title a {
  text-decoration: none;
  color: #2c3e50;
  transition: all 0.3s ease-in-out;
}

.blog_post .blog_content .blog_title a:hover {
  color: #33CEFF;
}

.blog_post .blog_content p {
  color: #6d6c6c;
  margin-bottom: 15px;
}

.blog_post .blog_content .gp-btn {
  font-size: 12px;
  font-size: 0.85714em;
  border-radius: 0;
  padding: 6px 8px;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #000;
  background: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.blog_post .blog_content .gp-btn:hover {
  color: #FFF;
  background-color: #33CEFF;
}

.blog-comments {
  padding-top: 10px;
  border-top: 1px solid #ecf0f1;
}

.blog-comments > a {
  display: inline-block;
  text-decoration: none;
  color: #111;
}

.blog-comments > a i {
  padding-right: 10px;
}

.blog-comments .blog-social {
  float: right;
}

.blog-comments .blog-social li {
  padding: 0 5px;
}

.blog-comments .blog-social li a {
  color: #111;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.blog-comments .blog-social li a:hover {
  color: #33CEFF;
}

/* Blog Two */
.blog-two p {
  margin-bottom: 50px;
}

.blog-two .blog_post {
  margin-bottom: 20px;
  padding: 0;
}

.blog-two .blog_post .blog-thumb-left {
  float: left;
  width: 50%;
  position: relative;
}

.blog-two .blog_post .blog-thumb-left img {
  width: 100%;
}

.blog-two .blog_post .blog-thumb-left .blog-comments {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(53, 152, 219, 0.8);
  border-top: none;
  padding: 10px;
}

.blog-two .blog_post .blog-thumb-left .blog-comments a {
  color: #FFF;
  transition: all 0.3s ease-in-out;
}

.blog-two .blog_post .blog-thumb-left .blog-comments a:hover {
  color: #111;
}

.blog-two .blog_post .blog_content {
  margin-left: 290px;
  padding: 10px 0;
}

.blog-two .blog_post .blog_content .blog_title {
  border-bottom: none;
  padding-bottom: 0;
}

.blog-two .blog_post .blog_content .gp-btn {
  margin-bottom: 0;
}

.blog-two .blog_post.bolg-right .blog_content {
  margin-left: 0;
  margin-right: 290px;
  padding: 10px;
}

.blog-two .blog_post .blog-thumb-right {
  position: relative;
  float: right;
  width: 50%;
}

.blog-two .blog_post .blog-thumb-right img {
  width: 100%;
}

.blog-two .blog_post .blog-thumb-right .blog-comments {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(53, 152, 219, 0.8);
  border-top: none;
  padding: 10px;
}

.blog-two .blog_post .blog-thumb-right .blog-comments a {
  color: #FFF;
  transition: all 0.3s ease-in-out;
}

.blog-two .blog_post .blog-thumb-right .blog-comments a:hover {
  color: #111;
}

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
#testimonial {
  padding: 50px 0;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

#testimonial .section-title {
  color: #FFF;
}

.testimonial-slider .testional .item {
  position: relative;
  padding: 50px 0;
  text-align: center;
}

.testimonial-slider .testional .item .cliten-thumbs {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 4px solid #33CEFF;
  margin: 0 auto 20px;
}

.testimonial-slider .testional .item .cliten-thumbs img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-slider .testional .item .testi-content p {
  color: #ddd;
  margin-bottom: 60px;
  font-style: italic;
  letter-spacing: 1px;
}

.testimonial-slider .testional .item h4 {
  color: #33CEFF;
  text-transform: uppercase;
  font-size: 16px;
  font-size: 1.14286em;
  margin-bottom: 10px;
  font-weight: 700;
}

.testimonial-slider .testional .item span {
  color: #FFF;
}

.testimonial-slider .owl-nav {
  display: none;
}

.owl-theme .owl-dots .owl-dot span {
  transition: all 0.3s ease;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #33CEFF;
  height: 10px;
  width: 15px;
}

/* Testimonial */
#feedback-slider2 .item {
  background: rgba(255, 255, 255, 0.8);
  padding: 30px;
}

#feedback-slider2 .item .testi-content p {
  color: #111;
}

#feedback-slider2 .item h4 {
  color: #33CEFF;
}

#feedback-slider2 .item span {
  color: #333;
}

/*--------------------------------------------------------------
  ##  Pricing Table
  --------------------------------------------------------------*/
#pricing_table {
  padding: 50px 0;
  text-align: center;
  background: #f5f5f5;
  overflow: hidden;
}

.table {
  background: #FFF;
  box-shadow: 0px -3px 29px -2px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.table .pricing_plan {
  border: 1px solid #ededed;
}

.table .pricing_plan .price {
  background: #33CEFF;
  padding-bottom: 20px;
}

.table .pricing_plan .price h4 {
  color: #111;
  text-transform: uppercase;
  font-size: 26px;
  font-size: 1.85714em;
  font-weight: 700;
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.table .pricing_plan .price p {
  color: #eee;
  line-height: 24px;
  line-height: 1.71429em;
  margin-bottom: 15px;
}

.table .pricing_plan .price .plan {
  display: inline-block;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 29px 0;
  background: #FFF;
}

.table .pricing_plan .price .plan h3 {
  font-size: 40px;
  font-size: 2.85714em;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}

.table .pricing_plan .price .plan span {
  font-size: 14px;
}

.table .pricing_plan .configuration {
  padding: 0;
  margin: 0;
  list-style: none;
}

.table .pricing_plan .configuration li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.table .pricing_plan .configuration li:nth-child(odd) {
  background: #f8f8f8;
}

.table .pricing_plan .configuration li:last-child {
  padding: 17px 0;
}

.table .pricing_plan .configuration li.none {
  color: red;
}

.table .pricing_plan .configuration li i {
  margin-right: 10px;
}

.table .pricing_plan .configuration li .gp-btn {
  border-radius: 30px;
  background: #FFF;
  transition: all 0.3s ease-in-out;
  color: #FFF;
  background: #33CEFF;
  text-decoration: none;
}

.table .pricing_plan .configuration li .gp-btn:hover {
  background: #111;
  color: #FFF;
  border-color: transparent;
}

/*--------------------------------------------------------------
  ##  brand
  --------------------------------------------------------------*/
#brand {
  text-align: center;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}

.brand {
  margin-top: 30px;
}

.brand .item {
  border: 1px solid #111;
}

.brand .owl-nav {
  display: none;
}

.owl-theme .owl-dots {
  margin-top: 20px;
}

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
#contact {
  text-align: center;
  padding: 100px 0;
}

#contact .contact-inner {
  padding: 0 50px;
}

.contact-header {
  text-align: left;
  margin-bottom: 50px;
}

.contact-header h2 {
  color: #000;
  margin-bottom: 15px;
  font-size: 32px;
  font-size: 2.28571em;
  text-transform: uppercase;
  font-weight: 700;
}

.contact-form-area {
  background: #FFF;
}

.contact-form-area .contact-info {
  text-align: center;
}

.contact-form-area .contact-info .info {
  background: #FFF;
  padding: 15px 0;
  box-shadow: 0px -3px 29px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 41px;
}

.contact-form-area .contact-info .info:last-child {
  margin-bottom: 0;
}

.contact-form-area .contact-info .info span i {
  color: #33CEFF;
  font-size: 30px;
  margin-bottom: 20px;
}

.contact-form-area .contact-info .info h4 {
  color: #33CEFF;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.14286em;
  text-transform: uppercase;
}

.contact-form-area .contact-info .info p {
  color: #5a5a5a;
  margin-bottom: 0;
}

#form input[type=text] {
  width: 48%;
  border: none;
  border-bottom: 2px solid #eee;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

#form input[type=text]:nth-child(2), #form input[type=text]:nth-child(4) {
  margin-right: 0;
}

#form input[type=text]:hover {
  border-color: #33CEFF;
}

#form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #eee;
  outline: none;
  transition: all 0.3s ease-in-out;
  height: 210px;
}

#form textarea:hover {
  border-color: #33CEFF;
}

#form .gp-btn {
  width: 100%;
  margin-top: 25px;
  border-radius: 0;
  background: #33CEFF;
  color: #FFF;
  border: none;
  padding: 12px 0;
  transition: all 0.3s ease;
}

#form .gp-btn:hover {
  background: #111;
  color: #FFF;
  border: transparent;
}

.contact-intro {
  text-align: left;
  padding: 50px 0;
}

.contact-intro h2 {
  font-size: 22px;
  font-size: 1.57143em;
  color: #000;
}

.contact-intro p {
  color: #000;
}

/* Contact Two */
#contact.contact-two {
  position: relative;
  padding: 0;
}

#contact.contact-two .contact-header h2 {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

#contact.contact-two .gmap3-area {
  height: 700px;
  width: 100%;
}

#contact.contact-two .contact-header {
  margin-bottom: 0;
}

#contact.contact-two .contact-form-area {
  padding: 50px;
  width: 40%;
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
}

#contact.contact-two .contact-form-area #form input[type=text], #contact.contact-two .contact-form-area #form textarea {
  border: 1px solid #111;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: #111;
}

#contact.contact-two .contact-form-area #form input[type=text] {
  width: 47%;
}

#contact.contact-two .contact-form-area #form input[type=text]:hover {
  border-color: #33CEFF;
}

#contact.contact-two .contact-form-area #form p {
  padding: 0 8px;
}

/*--------------------------------------------------------------
  ##  Map
  --------------------------------------------------------------*/
/* map */
.gmap3-area {
  height: 550px;
}

/*--------------------------------------------------------------
  ##  newsletter
  --------------------------------------------------------------*/
#newsletter {
  padding: 100px 0;
  text-align: center;
  background-size: cover;
}

#newsletter .news-wraper {
  max-width: 600px;
  margin: 0 auto;
}

#newsletter .news-wraper h3 {
  font-size: 35px;
  font-size: 2.5em;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 30px;
  color: #33CEFF;
}

#newsletter .news-wraper form {
  border: 2px solid #33CEFF;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

#newsletter .news-wraper form input[type=email] {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 15px 125px 15px 20px;
  outline: none;
  color: #FFF;
}

#newsletter .news-wraper form button {
  background: #33CEFF;
  padding: 15px 20px;
  color: #FFF;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  outline: none;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
}

#newsletter .news-wraper form button i {
  margin-right: 10px;
}

#newsletter .news-wraper form:hover {
  border-color: #FFF;
}

#newsletter .news-wraper form:hover button {
  background: #FFF;
  color: #333;
}

/*--------------------------------------------------------------
  ##  footer
  --------------------------------------------------------------*/
#footer {
  padding-top: 50px;
  text-align: center;
  background: #33CEFF;
}

#footer h2 {
  color: #FFF;
  font-weight: 600;
  font-size: 24px;
  font-size: 1.71429em;
  margin-bottom: 30px;
}

#footer .gp-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 50px;
}

#footer .gp-social-link li {
  display: inline-block;
  padding-right: 10px;
}

#footer .gp-social-link li a {
  display: block;
  border: 1px solid #333;
  color: #333;
  height: 40px;
  width: 40px;
  font-size: 20px;
  font-size: 1.42857em;
  line-height: 40px;
  transition: all 0.3s ease-in-out;
}

#footer .gp-social-link li a:hover {
  background: #333;
  color: #FFF;
  border-color: transparent;
}

.copyright {
  padding: 20px 0;
  border-top: 1px solid #13c5fd;
  text-align: center;
}

.copyright p {
  color: #eee;
  margin-bottom: 0;
  padding-right: 10px;
}

.copyright p a {
  color: #fff;
  text-decoration: none;
  position: relative;
  padding-left: 20px;
}

.copyright p a:before {
  content: '';
  position: absolute;
  height: 15px;
  width: 2px;
  background: #ddd;
  left: 10px;
  top: 2px;
}

/* Footer Two */
#footer.footer {
  background: #03141f;
  text-align: left;
}

#footer.footer .copyright {
  border-color: #1d292d;
}

#footer.footer h3 {
  color: #FFF;
  font-weight: 700;
  font-size: 22px;
  font-size: 1.57143em;
  text-transform: uppercase;
  margin-bottom: 30px;
}

#footer.footer .info i {
  float: left;
  color: #33CEFF;
  font-size: 25px;
  font-size: 1.78571em;
  height: 40px;
  width: 40px;
}

#footer.footer .info p {
  color: #a9a9a9;
  line-height: 22px;
  line-height: 1.57143em;
  margin-bottom: 20px;
}

#footer.footer .gp-social-link {
  margin-top: 30px;
}

#footer.footer .gp-social-link li a {
  text-align: center;
  color: #FFF;
  border-color: #FFF;
  height: 35px;
  width: 35px;
  font-size: 18px;
  font-size: 1.28571em;
  line-height: 35px;
}

#footer.footer .gp-social-link li a:hover {
  background: #33CEFF;
  border-color: transparent;
}

#footer.footer .news-wrapper {
  margin-bottom: 10px;
  border-bottom: 1px solid #0a2231;
  padding-bottom: 5px;
}

#footer.footer .news-wrapper:last-child {
  border-bottom: none;
}

#footer.footer .news-wrapper > a {
  display: block;
  float: left;
  transition: all 0.3s ease-in-out;
  width: 70px;
  height: 70px;
}

#footer.footer .news-wrapper > a img {
  width: 100%;
}

#footer.footer .news-wrapper .news-content {
  margin-left: 85px;
}

#footer.footer .news-wrapper .news-content a span {
  color: #ddd;
  font-size: 20px;
  font-size: 1.42857em;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
  transition: all 0.3s ease-in-out;
  line-height: 15px;
  line-height: 1.07143em;
}

#footer.footer .news-wrapper .news-content a p {
  color: #a5a5a5;
  margin-bottom: 0;
  line-height: 22px;
  line-height: 1.57143em;
}

#footer.footer .news-wrapper:hover a:hover {
  opacity: 0.7;
}

#footer.footer .news-wrapper:hover .news-content a {
  text-decoration: none;
}

#footer.footer .news-wrapper:hover .news-content a span {
  color: #33CEFF;
}

/* Flicker */
.flicker a {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.flicker a:hover {
  opacity: 0.7;
}

.flicker .copyright {
  border-color: #0b2333;
  text-align: center;
}

.flicker .copyright p {
  color: #929292;
}

/*--------------------------------------------------------------
  ##  Blog Page
  --------------------------------------------------------------*/
.blog-page #header {
  position: relative;
  background: #03141f;
}

/* Blog Single */
.blog-single {
  padding: 70px 0;
  background: #f5f5f5;
}

.blog-wrapper .blog-thumb {
  position: relative;
  margin-bottom: 20px;
}

.blog-wrapper .blog-thumb img {
  width: 100%;
}

.blog-wrapper .blog_post {
  margin-bottom: 20px;
}

.blog-wrapper .post-meta {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}

.blog-wrapper .post-meta li {
  display: inline;
  padding-right: 20px;
}

.blog-wrapper .post-meta li a {
  text-decoration: none;
  font-weight: 500;
  color: #33CEFF;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.blog-wrapper .post-meta li a i {
  margin-right: 10px;
}

.blog-wrapper h2 {
  color: #222;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 28px;
  font-size: 2em;
}

.blog-wrapper p {
  margin-bottom: 20px;
  color: #545454;
}

.blog-wrapper blockquote {
  font-style: italic;
  color: #33ceff;
  font-weight: 600;
  border-left: none;
  border-top: 1px solid #33CEFF;
  border-bottom: 1px solid #33CEFF;
  padding: 15px;
  font-size: 16px;
  font-size: 1.14286em;
}

.blog-wrapper .tag-area i {
  margin-right: 10px;
  color: #33CEFF;
}

.blog-wrapper .tag-area a {
  text-decoration: none;
  color: #111;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}

.blog-wrapper .tag-area a:hover {
  color: #33CEFF;
}

.blog-sub-title {
  font-size: 24px;
  font-size: 1.71429em;
  color: #111;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 15px;
}

.blog-sub-title:after {
  position: absolute;
  background: #33CEFF;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 50px;
  content: '';
}

.athor {
  padding: 10px;
  margin-bottom: 30px;
}

.athor .athor-inner {
  border: 1px solid #ddd;
  padding: 10px;
}

.athor .athor-inner .thumb {
  float: left;
}

.athor .content {
  margin-left: 110px;
}

.athor .content h4 {
  font-size: 20px;
  font-size: 1.42857em;
  font-weight: 700;
  margin-bottom: 10px;
}

.athor .content span {
  color: #999;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}

ol {
  margin: 0;
  list-style: none;
}

ol.comment-list {
  padding: 0;
}

article {
  padding: 15px 0;
  position: relative;
}

article .reply {
  position: absolute;
  right: 0;
  bottom: 15px;
}

article .reply a {
  text-decoration: none;
  color: #33CEFF;
}

article .reply a i {
  margin-left: 10px;
}

article {
  border-bottom: 1px solid #eee;
  paddin: 20px 0;
}

.comment-meta img {
  float: left;
}

.comment-meta a {
  margin-left: 110px;
  text-decoration: none;
  color: #ccc;
  margin-bottom: 10px;
  display: block;
  font-weight: 600;
}

.comment-meta a i {
  margin-right: 5px;
}

.comment-meta a.url {
  font-size: 16px;
  font-size: 1.14286em;
  color: #111;
  text-decoration: none;
  font-weight: 700;
  margin-bottom: 5px;
}

.comment-content {
  margin-left: 110px;
}

.comment-content p {
  margin-bottom: 20px;
  color: #676767;
}

.comment-respond .comment-reply-title {
  font-size: 22px;
  font-size: 1.57143em;
  color: #111;
  font-weight: 700;
  margin: 20px 0;
}

.comment-respond .comment-form p {
  width: 32%;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 15px;
}

.comment-respond .comment-form p:nth-child(3) {
  margin-right: 0;
}

.comment-respond .comment-form p input {
  width: 100%;
  padding: 5px 10px;
  outline: none;
  border: 1px solid #ddd;
  transition: 0.3s ease-in-out;
}

.comment-respond .comment-form p input:hover {
  border-color: #33CEFF;
}

.comment-respond .comment-form .comment-form-comment {
  padding-right: 5px;
  width: 100%;
}

.comment-respond .comment-form .comment-form-comment textarea {
  width: 100%;
  height: 150px;
  outline: none;
  padding: 10px;
  border: 1px solid #ddd;
  transition: 0.3s ease-in-out;
}

.comment-respond .comment-form .comment-form-comment textarea:hover {
  border-color: #33CEFF;
}

.comment-respond #submit {
  background: #33CEFF;
  border: none;
  color: #FFF;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
}

.comment-respond #submit:hover, .comment-respond #submit:focus {
  background: #111;
}

.more-blog {
  display: block;
  margin: 0 auto;
  color: #FFF;
  padding: 10px 0;
  background: #33CEFF;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  width: 150px;
  text-transform: uppercase;
  font-weight: 600;
}

.more-blog:hover {
  background: #111;
  color: #FFF;
  text-decoration: none;
}

/*--------------------------------------------------------------
  ##  responsive
  --------------------------------------------------------------*/
@media screen and (max-width: 1440px) {
  #contact.contact-two .contact-form-area {
    width: 50%;
    padding: 25px;
  }
}

@media screen and (max-width: 1280px) {
  /* Blog Two  */
  .blog-two .blog_post .blog-thumb-left, .blog-two .blog_post .blog-thumb-right {
    float: none;
    width: 100%;
  }
  .blog-two .blog_post .blog_content {
    margin: 0 15px;
  }
  .blog-two .blog_post.bolg-right .blog_content {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  /* Header */
  #header .navbar-header .navbar-brand {
    padding: 10px 0;
  }
  #header .navbar-nav {
    padding: 20px 0;
  }
  /* Banner */
  .slider-caption-one h2 {
    font-size: 50px;
    font-size: 3.57143em;
  }
  .slider-caption-one h3 {
    font-size: 20px;
    font-size: 1.42857em;
    margin-bottom: 25px;
  }
  .gp-bn-btn {
    padding: 10px 40px;
  }
  .switcher {
    bottom: 25px;
  }
  .slider-caption-two {
    left: 10%;
  }
  .slider-caption-two h2 {
    font-size: 35px;
    font-size: 2.5em;
  }
  .slider-caption-two h4 {
    margin-bottom: 0;
  }
  .slider-caption-three {
    left: 10%;
  }
  .slider-caption-three h2 {
    font-size: 35px;
    font-size: 2.5em;
  }
  .slider-caption-three h4 {
    font-size: 20px;
    font-size: 1.42857em;
    margin-bottom: 0;
  }
  .slider-caption-three h3 {
    font-size: 22px;
    font-size: 1.57143em;
    margin-bottom: 1px;
  }
  /* Skills */
  .our-skills .skills-title h2 {
    margin-bottom: 10px;
  }
  .our-skills .skills-title p {
    margin-bottom: 10px;
  }
  progress {
    height: 15px;
  }
  /* Service */
  .service-item .service .service_content p br {
    display: none;
  }
  /* Portfolio */
  .portfolio_container .portfolio-item .overlay .work-caption h2 {
    font-size: 18px;
    font-size: 1.28571em;
  }
  .portfolio_container .portfolio-item:hover .overlay .work-caption .view {
    margin-bottom: 20px;
  }
  /* Team */
  .team_member {
    border: 2px solid #33CEFF;
    padding: 5px;
  }
  .team_member .team_intro {
    padding: 10px 20px;
  }
  .team_member .team_intro span {
    margin-bottom: 10px;
  }
  .team_member .team_intro .team_link li {
    margin-right: 8px;
  }
  .team_member:hover .team_intro h4 {
    margin-top: 0;
  }
  .team_member:hover .blog-thumb-left .blog-comments {
    bottom: -34px;
    padding: 7px;
  }
  .team_member:hover .blog-thumb-left .blog-comments li {
    padding: 0 3px;
  }
  /* Team Two */
  #team-slider .team_member:hover .team_intro h4 {
    margin-top: 70px;
  }
  /* Contact */
  #form {
    /* Contact */
  }
  #form input[type=text] {
    width: 47%;
  }
  #form input[type=text] {
    width: 47%;
  }
  #form .gp-btn {
    margin-top: 18px;
  }
}

@media screen and (max-width: 991px) {
  /* Header */
  #header .navbar-header .navbar-brand {
    width: 150px;
    padding: 16px 0;
  }
  #header .navbar-nav li {
    padding: 0 6px;
  }
  /* Team Two */
  #team-slider .team_member:hover .team_intro h4 {
    margin-top: 50px;
  }
  /* Countup Two */
  .countup-two {
    padding: 50px 0;
  }
  .countup-two .counter-box {
    margin-bottom: 15px;
  }
  /* Contact Two  */
  #contact.contact-two .contact-form-area {
    right: 40px;
  }
  #contact.contact-two .gmap3-area {
    height: 600px;
  }
}

@media screen and (max-width: 768px) {
  /* Header */
  .header_wrapper {
    width: 100%;
    display: none;
  }
  #header .navbar-header .navbar-brand {
    padding: 0;
    margin-left: 20px;
  }
  #header .navbar-collapse {
    padding: 0;
  }
  #header .navbar-nav {
    text-align: center;
    margin: 0;
  }
  #mobile-header {
    display: block;
  }
  .navbar-toggle {
    margin: 12px 20px 12px 0;
  }
  .navbar-fixed-top {
    background: rgba(0, 0, 0, 0.8) !important;
  }
  .navbar-fixed-top .navbar-header .navbar-brand {
    padding: 0 !important;
  }
  .gp-primary-menu {
    background-color: transparent;
  }
  /* Banner */
  .swiper-banner .swiper-slide {
    height: 370px;
  }
  .swiper-banner .swiper-slide img {
    height: 100%;
    width: auto;
  }
  .slider-caption-one h2 {
    font-size: 30px;
    font-size: 2.14286em;
  }
  .slider-caption-one h2:before, .slider-caption-one h2:after {
    width: 165px;
  }
  .slider-caption-one h3 {
    font-size: 18px;
    font-size: 1.28571em;
  }
  .gp-bn-btn {
    padding: 10px 28px;
  }
  /* About */
  .about-box {
    margin-bottom: 50px;
  }
  /* Skills */
  .our-skills .skills {
    display: block;
  }
  .our-skills .skill-bar {
    padding: 50px 0;
  }
  /* Skills two */
  .our-skills-two {
    padding: 50px 0;
  }
  .skills-description {
    padding: 0;
  }
  .skills-description h2 {
    margin-top: 20px;
  }
  /* Service two */
  #service-two {
    padding: 50px 20px;
  }
  #service-two .service .overlay {
    padding: 50px 20px;
  }
  #service-two .service .overlay p br {
    display: none;
  }
  /* Counter */
  .counter-box {
    margin-bottom: 20px;
  }
  /* Portfolio */
  .portfolio_container .portfolio-item .overlay .work-caption {
    width: 100%;
  }
  .portfolio_container .portfolio-item .overlay .work-caption .view {
    margin-bottom: 15px !important;
  }
  /* Blog Two  */
  .blog-two .blog_post .blog-thumb-left, .blog-two .blog_post .blog-thumb-right {
    float: none;
    width: 100%;
  }
  .blog-two .blog_post .blog_content {
    margin: 0 15px;
  }
  .blog-two .blog_post.bolg-right .blog_content {
    margin-right: 0;
  }
  /* Team */
  .team_member {
    margin-bottom: 20px;
  }
  .team_member .team_intro {
    bottom: -80%;
  }
  .team_member .team_intro span {
    margin-bottom: 20px;
  }
  .team_member:hover .team_intro h4 {
    margin-top: 85px;
  }
  /* Team Two */
  #team-slider .team_member:hover .team_intro h4 {
    margin-top: 50px;
  }
  /* Contact */
  #contact .contact-inner {
    padding: 30px 0;
  }
  .contact-form-area .contact-info .info {
    margin-bottom: 20px;
  }
  /* Contact Two */
  #contact {
    padding: 0;
  }
  #contact.contact-two .gmap3-area {
    height: 300px;
  }
  #contact.contact-two .contact-form-area {
    position: static;
    transform: translateY(0);
    width: 100%;
    background: #f5f5f5;
  }
  #contact.contact-two .contact-form-area #form p {
    padding: 0 14px;
  }
  /* Newsletter */
  #newsletter .news-wraper form {
    width: 400px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  /* Section Title */
  .section-title {
    font-size: 24px;
    font-size: 1.71429em;
  }
  /* Banner */
  .gp-bn-btn {
    margin-right: 10px;
    padding: 8px 16px;
  }
  .slider-caption-one h3 {
    font-size: 14px;
    font-size: 1em;
  }
  .slider-caption-two h2 {
    font-size: 25px;
    font-size: 1.78571em;
  }
  .slider-caption-two h4 {
    font-size: 18px;
    font-size: 1.28571em;
  }
  .slider-caption-two p {
    font-size: 14px;
    font-size: 1em;
  }
  .slider-caption-two p br {
    display: none;
  }
  .slider-caption-three h2 {
    font-size: 22px;
    font-size: 1.57143em;
    margin-bottom: 15px;
  }
  .slider-caption-three h4 {
    font-size: 18px;
    font-size: 1.28571em;
  }
  .slider-caption-three h3 {
    font-size: 20px;
    font-size: 1.42857em;
  }
  .swiper-pagination {
    right: 30px;
  }
  /* About */
  .caption br {
    display: none;
  }
  /* Skills Two */
  .our-skills-two .skill-bar {
    padding-right: 0;
  }
  /* CountUp */
  .full-width {
    width: 100%;
  }
  /* Team */
  .team_member {
    margin-bottom: 20px;
  }
  .team_member .team_intro {
    bottom: -83%;
  }
  .team_member .team_intro span {
    margin-bottom: 20px;
  }
  .team_member:hover .team_intro h4 {
    margin-top: 135px;
  }
  .portfolio .portfolioFilter li {
    margin-right: 5px;
  }
  .portfolio .portfolioFilter li a {
    padding: 6px;
  }
  /* Testimonial */
  #testimonial .section-title {
    margin-bottom: 0;
  }
  .testimonial-slider .testional .item .testi-content {
    padding-top: 0;
  }
  .testimonial-slider .testional .item .testi-content p br {
    display: none;
  }
  /* Newsletter */
  #newsletter {
    padding: 50px 0;
  }
  #newsletter .news-wraper h3 {
    font-size: 30px;
    font-size: 2.14286em;
  }
  #newsletter .news-wraper form {
    width: 310px;
    margin: 0 auto;
  }
  #newsletter .news-wraper form input[type=email] {
    padding: 10px 115px 10px 15px;
  }
  #newsletter .news-wraper form button {
    padding: 10px;
  }
  /* Contact */
  #contact .contact-inner {
    padding: 0;
  }
  #contact .contact-inner .contact-header h2 {
    margin-top: 30px;
  }
  #contact #form input[type=text] {
    width: 100%;
  }
  #contact #form .gp-btn {
    margin-bottom: 30px;
  }
  .return-to-top {
    right: 20px;
    bottom: 20px;
  }
  /* Contact Two */
  #contact.contact-two .contact-form-area {
    padding: 20px 10px;
  }
  #contact.contact-two .contact-form-area #form p {
    padding: 0;
  }
}

/* Blog Signle */
.comment-respond .comment-form p {
  margin-right: 2px;
}

/* Widget */
.widget_dt_search .search-form {
  margin-top: 30px;
}

/*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ## Search
  --------------------------------------------------------------*/
.widget_dt_search .search-form {
  width: 100%;
}

.widget_dt_search .search-form label {
  position: relative;
  width: 270px;
}

.widget_dt_search .search-form .search-icon {
  position: absolute;
  right: 6px;
  top: 12px;
}

.widget_dt_search input[type="search"] {
  width: 100%;
  border: 1px solid #eeeeee;
  outline: none;
  padding: 10px 20px;
  color: #111;
  font-size: 14px;
  font-size: 1em;
}

.widget_dt_search .search-submit {
  display: none;
}

/*--------------------------------------------------------------
  ## Dt Custom Menu
  --------------------------------------------------------------*/
/* Custom Menu */
.widget {
  margin-bottom: 50px;
}

.widget .widget-title {
  position: relative;
  font-size: 20px;
  font-size: 1.42857em;
  color: #111;
  margin-top: 0;
  margin-bottom: 40px;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.widget .widget-title:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  left: 0;
  bottom: 0;
  background: #33CEFF;
}

.widget_nav_menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget_nav_menu ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
}

.widget_nav_menu ul li:last-child {
  border-bottom: none;
}

.widget_nav_menu ul li a {
  color: #6d6c6c;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 600;
}

.widget_nav_menu ul li a:hover {
  color: #33CEFF;
}

/*--------------------------------------------------------------
  ## Dt Tags
  --------------------------------------------------------------*/
/* Dt Tags */
.dt-tag a {
  color: #6d6c6c;
  border: 1px solid #eee;
  text-decoration: none;
  padding: 4px 8px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
  transition: all 0.3s ease;
}

.dt-tag a:hover {
  background: #33CEFF;
  color: #FFF;
}

/*--------------------------------------------------------------
  ## Dt Resent Post
  --------------------------------------------------------------*/
/* Widget Resent Post */
.widget_recent_entries .resent-post {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.widget_recent_entries .resent-post .post-thumbs {
  float: left;
  border-radius: 5px;
  overflow: hidden;
}

.widget_recent_entries .resent-post .post-content {
  margin-left: 90px;
}

.widget_recent_entries .resent-post .post-content h5 {
  font-size: 14px;
  font-size: 1em;
  margin-top: 0;
}

.widget_recent_entries .resent-post .post-content h5 a {
  color: #111111;
  line-height: 20px;
  line-height: 1.42857em;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 700;
}

.widget_recent_entries .resent-post .post-content h5 a:hover {
  color: #33CEFF;
}

.widget_recent_entries .resent-post .post-content .post-meta {
  padding: 0;
  margin-left: 0;
  list-style: none;
}

.widget_recent_entries .resent-post .post-content .post-meta li {
  display: inline;
  padding-right: 10px;
}

.widget_recent_entries .resent-post .post-content .post-meta li a {
  color: #33CEFF;
  text-decoration: none;
}

.widget_recent_entries .resent-post .post-content .post-meta li a i {
  padding-right: 10px;
}

/*--------------------------------------------------------------
  ## Dt Archive
  --------------------------------------------------------------*/
/* Widget Dt Archives */
.widget_archive ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget_archive ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
  position: relative;
}

.widget_archive ul li a {
  color: #6d6c6c;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.widget_archive ul li a span {
  padding-left: 5px;
}

.widget_archive ul li p {
  position: absolute;
  right: 0;
  top: 10px;
  transition: all 0.3s ease-in-out;
}

.widget_archive ul li:hover a {
  color: #33CEFF;
}

.widget_archive ul li:hover p {
  color: #33CEFF;
}
