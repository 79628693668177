#blog {
  padding: 50px 0;
  text-align: center;
  overflow: hidden; }

.blog_post {
  text-align: left;
  border: 1px solid #ecf0f1;
  padding: 15px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block; } }

  .blog-thumb {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      transition: all 0.8s ease-in-out;
      overflow: hidden; }

    .blog-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color_rgba;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out; }

    &:hover {
      img {
        transform: scale(1.2); }

      .blog-overlay {
        opacity: 1; } } }



  .blog_content {
    .post_meta {
      margin-bottom: 10px;
      li {
        padding-right: 10px;
        position: relative;

        &:first-child {
          &:after {
            content: '';
            position: absolute;
            height: 12px;
            width: 2px;
            color: #DDD;
            background: #989898;
            right: 0;
            top: 4px; } }

        &:last-child {
          margin-left: 10px; }

        a {
          text-decoration: none;
          color: #989898;
          i {
            padding-right: 5px; } } } }
    .blog_title {
      padding-bottom: 5px;
      border-bottom: 1px solid #f5f5f5;
      @include font-size(20);
      text-transform: uppercase;
      font-weight: 500;
      display: block;
      font-weight: 700;

      a {
        text-decoration: none;
        color: $color_header;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color_theme; } } }

    p {
      color: $color_text;
      margin-bottom: 15px; }


    .gp-btn {
      @include font-size(12);
      border-radius: 0;
      padding: 6px 8px;
      margin-bottom: 20px;
      text-transform: capitalize;
      color: #000;
      background: rgba(0,0,0, 0.2);
      border-color: transparent;
      text-decoration: none;
      display: inline-block;
      transition: all 0.3s ease-in-out;
      font-weight: 600;



      &:hover {
        color: #FFF;
        background-color: $color_theme; } } } }



.blog-comments {
  padding-top: 10px;
  border-top: 1px solid #ecf0f1;


  >a {
    display: inline-block;
    text-decoration: none;
    color: #111;
    i {
      padding-right: 10px; } }


  .blog-social {
    float: right;
    li {
      padding: 0 5px;
      a {
        color: #111;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: $color_theme; } } } } }



/* Blog Two */
.blog-two {

  p {
    margin-bottom: 50px; }

  .blog_post {
    margin-bottom: 20px;
    padding: 0;

    .blog-thumb-left {
      float: left;
      width: 50%;
      position: relative;
      img {
        width: 100%; }

      .blog-comments {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(53, 152, 219, 0.8);
        border-top: none;
        padding: 10px;

        a {
          color: #FFF;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: #111; } } } }

    .blog_content {
      margin-left: 290px;
      padding: 10px 0;

      .blog_title {
        border-bottom: none;
        padding-bottom: 0; }

      .gp-btn {
        margin-bottom: 0; } }

    &.bolg-right {
      .blog_content {
        margin-left: 0;
        margin-right: 290px;
        padding: 10px; } }

    .blog-thumb-right {
      position: relative;
      float: right;
      width: 50%;
      img {
        width: 100%; }

      .blog-comments {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(53, 152, 219, 0.8);
        border-top: none;
        padding: 10px;

        a {
          color: #FFF;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: #111; } } } } } }
