/*--------------------------------------------------------------
  ##  Loading
  --------------------------------------------------------------*/
@import "loading";

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
@import "banner";

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
@import "about";

/*--------------------------------------------------------------
  ##  Service
  --------------------------------------------------------------*/
@import "service";

/*--------------------------------------------------------------
  ##  Portfolio
  --------------------------------------------------------------*/
@import  "portfolio";

/*--------------------------------------------------------------
  ##  Countup
  --------------------------------------------------------------*/
@import  "countup";

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
@import  "team";


/*--------------------------------------------------------------
  ##  Skills
  --------------------------------------------------------------*/
@import  "skills";

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
@import  "blog";

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
@import  "testimonial";

/*--------------------------------------------------------------
  ##  Pricing Table
  --------------------------------------------------------------*/
@import  "pricing_table";

/*--------------------------------------------------------------
  ##  brand
  --------------------------------------------------------------*/
@import  "brand";

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
@import  "contact";

/*--------------------------------------------------------------
  ##  Map
  --------------------------------------------------------------*/
@import  "map";

/*--------------------------------------------------------------
  ##  newsletter
  --------------------------------------------------------------*/
@import  "newsletter";


/*--------------------------------------------------------------
  ##  footer
  --------------------------------------------------------------*/
@import  "footer";

/*--------------------------------------------------------------
  ##  Blog Page
  --------------------------------------------------------------*/
@import  "blog-page";

/*--------------------------------------------------------------
  ##  responsive
  --------------------------------------------------------------*/
@import  "responsive";


