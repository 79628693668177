
a {
  text-decoration: none !important; }

a:focus {
  text-decoration: none;
  color: #FFF; }

.gp-bn-btn {
  position: relative;
  background-color: $color_theme;
  padding: 12px 50px;
  text-decoration: none;
  display: inline-block;
  margin-right: 30px;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #111;
    color: #FFF;
    text-decoration: none;
    border-color: #111; } }

.gp-bn-active {
  background-color: transparent;
  border: 1px solid #fff;
  margin-right: 0;
  padding: 11px 50px;

  &:hover {
    border-color: transparent; } }

.gp-btn {
  color: #000;
  padding: 10px 15px;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid $color_theme;
  display: inline-block;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative; }

.gp-bn-btn-2 {
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF; }


