// Fonts
$font_primary: 'Open Sans', sans-serif;

// Color
$color_theme: #33CEFF;
$color_theme-1: #dddddd;
$color_theme-2: #f89406;
$color_theme-3: #642d88;
$color_text: #6d6c6c;
$color_header: #2c3e50;
$color_rgba: rgba(53, 152, 219, 0.8);
