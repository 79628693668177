#brand {
  text-align: center;
  padding: 50px 0;
  position: relative;
  overflow: hidden; }


.brand {
  margin-top: 30px;
  .item {
    border: 1px solid #111; }
  .owl-nav {
    display: none; } }
.owl-theme {
  .owl-dots {
    margin-top: 20px; } }
