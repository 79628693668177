#contact {
  text-align: center;
  padding: 100px 0;

  .contact-inner {
    padding: 0 50px; } }


.contact-header {
  text-align: left;
  margin-bottom: 50px;

  h2 {
    color: #000;
    margin-bottom: 15px;
    @include font-size(32);
    text-transform: uppercase;
    font-weight: 700; }

  p {} }


.contact-form-area {
  background: #FFF;

  .contact-info {
    text-align: center;

    .info {
      background: #FFF;
      padding: 15px 0;
      box-shadow: 0px -3px 29px -2px rgba(0, 0, 0, 0.2);
      margin-bottom: 41px;

      &:last-child {
        margin-bottom: 0; }

      span {
        i {
          color: $color_theme;
          font-size: 30px;
          margin-bottom: 20px; } }

      h4 {
        color: $color_theme;
        font-weight: 700;
        @include font-size(16);
        text-transform: uppercase; }
      p {
        color: #5a5a5a;
        margin-bottom: 0; } } } }


#form {

  input[type=text] {
    width: 48%;
    border: none;
    border-bottom: 2px solid #eee;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    outline: none;
    transition: all 0.3s ease-in-out;

    &:nth-child(2), &:nth-child(4) {
      margin-right: 0; }

    &:hover {
      border-color: $color_theme; } }

  textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #eee;
    outline: none;
    transition: all 0.3s ease-in-out;
    height: 210px;

    &:hover {
      border-color: $color_theme; } }

  .gp-btn {
    width: 100%;
    margin-top: 25px;
    border-radius: 0;
    background: $color_theme;
    color: #FFF;
    border: none;
    padding: 12px 0;
    transition: all 0.3s ease;

    &:hover {
      background: #111;
      color: #FFF;
      border: transparent; } } }

.contact-intro {
  text-align: left;
  padding: 50px 0;

  h2 {
    @include font-size(22);
    color: #000; }

  p {
    color: #000; } }


/* Contact Two */
#contact.contact-two {
  position: relative;
  padding: 0;

  .contact-header {
    h2 {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px; } }

  .gmap3-area {
    height: 700px;
    width: 100%; }

  .contact-header {
    margin-bottom: 0; }

  .contact-form-area {
    padding: 50px;
    width: 40%;
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255,255,255,0.8);

    #form {
      input[type=text], textarea {
        border: 1px solid #111;
        transition: all 0.3s ease-in-out;
        background-color: transparent;
        color: #111; }

      input[type=text] {
        width: 47%;

        &:hover {
          border-color: $color_theme; } }

      p {
        padding: 0 8px; } } } }





