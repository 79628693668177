.our-skills {
  overflow: hidden;
  position: relative;

  .skills {
    display: flex;
    align-items: center; }

  .skills-title {
    text-align: center;

    h2 {
      color: #000;
      font-weight: 700;
      @include font-size(30);
      margin-bottom: 20px; }

    p {
      color: #000;
      margin-bottom: 30px; } }

  p {
    color: #000;
    margin-bottom: 10px; }

  .skills-thumb {
    img {
      width: 100%; } } }

.skills-wrapper {
  max-width: 550px;
  margin: 0 auto;
  top: 50%; }


.skills-heading {
  span {
    font-size: 16px;
    font-weight: 700;
    color: #111111; }

  p {
    color: #000;
    float: right;
    margin-bottom: 0; } }



progress {
  border: 0;
  height: 15px;
  width: 100%;
  margin-bottom: 10px; }



progress::-webkit-progress-bar {
  background-color: #eaeaea; }




progress::-webkit-progress-value {
  background-color: $color_theme;
  height: 15px; }


/* Skills Two */
.our-skills-two {
  padding: 150px 0;
  background-size: cover;
  position: relative;

  .overlay {
    background: rgba(0,0,0, 0.8); }

  .skill-bar {
    padding-right: 100px;

    .skills-title {
      h2 {
        color: $color_theme;
        font-weight: 700;
        margin-bottom: 30px; } }

    .skills-heading {
      span, p {
        color: #FFF; } } }

  progress {}


  progress::-webkit-progress-bar {
    padding: 1px;
    border: 1px solid #FFF;
    background-color: transparent;
    height: 9px; }


  progress::-webkit-progress-value {
    height: 5px; } }


.skills-description {


  h2 {
    color: #FFF;
    margin-top: 0;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding-bottom: 15px;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      background: $color_theme;
      width: 50px;
      content: '';
      height: 4px; } }


  p {
    color: #ddd;
    margin-bottom: 45px; }

  .gp-btn {
    color: #FFF;
    border-color: #FFF;
    border-radius: 0;
    background: $color_theme;
    border-color: transparent;

    &:hover {
      background-color: transparent;
      border-color: #FFF; } } }
