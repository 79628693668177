#about {
  text-align: center;
  padding: 70px 0;
  background: #f5f5f5;
  overflow: hidden;

  .caption {
    margin-bottom: 70px;
    color: #111; } }

.about-box {
  border-right: 10px;
  background: #FFF;
  padding: 60px 20px 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  text-align: left;
  position: relative;


  .icon-box {
    height: 70px;
    width: 70px;
    background: #FFF;
    transition: all 0.3s ease-in-out;
    margin: 0 auto;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    top: -35px;
    left: 20px;
    border: 1px solid #ddd;


    i {
      @include font-size(30);
      line-height: 76px;
      color: #000;
      transition: all 0.3s ease-in-out; } }

  .about-title {
    @include font-size(20);
    color: #111;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out; }

  p {
    margin-bottom: 0;
    color: #111;
    @include font-size(15); }


  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    .icon-box {
      background: $color_theme;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 1px;


      i {
        color: #FFF; } }

    .about-title {
      color: $color_theme; } } }



/* Aboute Two */

.about-two {
  padding: 80px 0;
  text-align: center;


  .about-box {
    text-align: center;
    padding: 20px;

    i {
      @include font-size(55);
      color: #000;
      transition: all 0.3s ease-in-out; }

    &:hover {

      i {
        color: $color_theme; } } } }
