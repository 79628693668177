.countup {
  padding: 100px 0;
  position: relative;
  background-size: cover;
  overflow: hidden;
  background-size: cover; }

.counter-box {
  border: 3px solid $color_theme;
  padding: 15px 0;
  background: rgba(0,0,0, 0.5);


  .count {
    i {
      font-size: 50px;
      color: $color_theme;
      margin-bottom: 25px; }

    h2 {
      @include font-size(50);
      color: #FFF;
      font-weight: 700; }

    p {
      color: #FFF;
      @include font-size(16);
      font-weight: 300; } } }

/* CountUp Two */
.countup-two {
  padding: 100px 0;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  .overlay {
    background: rgba(53, 152, 219, 0.5); }

  .counter-box {
    border: none;
    background: rgba(0,0,0,0.8); } }

