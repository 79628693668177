#gp-team {
  padding: 70px 0;
  text-align: center;
  background: #f5f5f5;
  overflow: hidden;


  .caption {
    margin-bottom: 40px; } }

.team_member {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  border: 3px solid $color_theme;

  .team_thumb {
    img {
      width: 100%; } }

  .team_intro {
    position: absolute;
    bottom: -75%;
    left: 0;
    background-color: rgba(53,152,219, .9);
    height: 100%;
    width: 100%;
    padding: 20px;
    text-align: left;
    border-radius: 10px;
    transition: all 0.6s cubic-bezier(.42,-0.4,.57,1.53);

    h4 {
      text-transform: uppercase;
      color: #111;
      margin-top: 0;
      @include font-size(22);
      transition: all 0.3s ease-in-out;
      font-weight: 700; }

    h5 {
      @include font-size(18);
      color: #111;
      font-weight: 700; }

    span {
      color: #FFF;
      display: block;
      margin-bottom: 50px; }

    p {
      color: #fff; }

    .team_link {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: inline-block;
        margin-right: 10px;
        height: 30px;
        width: 30px;
        border: 1px solid #FFF;
        text-align: center;
        background: transparent;
        transition: all 0.3s ease-in-out;
        a {

          i {
            @include font-size(15);
            @include line-height(28);
            color: #FFF; } } } } }


  &:hover {
    .team_intro {
      bottom: 0;
      border-radius: 0;

      h4 {
        margin-top: 40px; }

      .team_link {
        li {

          &:hover {
            background: #111;
            border-color: transparent;

            a {
              i {
                color: $color_theme; } } } } } } } }



/* Team Two */
.team-two {
  .owl-carousel {
    position: relative;
    .owl-nav {
      .owl-next, .owl-prev {
        font-family: "dt-icons" !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        margin: 0;
        padding: 0;

        &:before, &:after {
          @include font-size(70);
          color: #111; }

        &:hover {
          background-color: transparent; } } }

    .owl-nav {
      .owl-prev {
        left: -100px;
        &:before {
          content: "\e03f"; } }

      .owl-next {
        right: -100px;
        &:after {
          content: "\e04b"; } } } } }

#team-slider {
  .team_member {
    padding: 0;
    border: 2px solid $color_theme;

    .team_thumb {
      img {
        transition: all 0.3s ease-in-out; } }
    .team_intro {
      text-align: center !important;
      bottom: 0;
      border-radius: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      h4 {
        margin-top: 150px;
        font-weight: 700;
        position: relative;
        padding-bottom: 15px;
        letter-spacing: 1px;

        &:after {
          position: absolute;
          content: '';
          background: #333;
          height: 2px;
          width: 70px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%); } }

      p {
        margin-bottom: 20px; } }

    &:hover {
      .team_thumb {
        img {
          transform: scale(1.1); } }
      .team_intro {
        opacity: 1;
        h4 {
          margin-top: 100px; } } } } }






