#service {
  text-align: center;
  padding: 70px 0;
  position: relative;
  background: #f5f5f5;
  overflow: hidden; }


.service-item {
  text-align: center;

  .service {
    padding: 20px;
    transition: all 0.5s ease;
    cursor: pointer;
    text-align: center;

    .icon-box {
      margin: 0 auto;
      height: 90px;
      width: 90px;
      border-radius: 50%;
      margin-bottom: 30px;
      transition: all 0.3s ease-in-out;
      background: #FFF;
      position: relative;
      i {
        @include font-size(35);
        color: #111;
        position: relative;
        z-index: 10;
        line-height: 100px; }

      .icon-overlay {
        position: absolute;
        height: 30%;
        width: 30%;
        background: $color_theme;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        margin: auto; } }

    .service_content {
      .title {
        color: #111;
        @include font-size(18);
        margin-top: 0;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
        font-weight: 600; }

      p {
        color: #111;
        margin-bottom: 0; } }

    &:hover {
      .icon-box {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 1px;
        i {
          color: #FFF; }
        .icon-overlay {
          z-index: 1;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0; } }

      .service_content {
        .title {
          color: $color_theme; } } } } }



/* Service Two */

.service-two {
  padding: 100px 0;
  text-align: center;


  .service {
    margin: 1px;
    border: 1px solid #a2a2a2;
    height: 260px;
    padding: 55px 0;

    i {
      @include font-size(70);
      color: $color_theme;
      display: block;
      margin-bottom: 20px; }

    .overlay {
      background: $color_theme;
      padding: 50px 0;
      opacity: 0;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;

      h3 {
        color: #FFF;
        font-weight: 700;
        margin-bottom: 30px; }

      p {
        color: #111; } }

    &:hover {
      .overlay {
        transform: scaleX(1);
        opacity: 1; } } } }

