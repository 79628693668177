@media screen and (max-width: 1440px) {
  #contact.contact-two {
    .contact-form-area {
      width: 50%;
      padding: 25px; } } }

@media screen and (max-width: 1280px) {
  /* Blog Two  */
  .blog-two {
    .blog_post {
      .blog-thumb-left, .blog-thumb-right {
        float: none;
        width: 100%; }


      .blog_content {
        margin: 0 15px; }

      &.bolg-right {
        .blog_content {
          margin-right: 0; } } } } }

@media screen and (max-width: 1024px) {

  /* Header */
  #header {
    .navbar-header {
      .navbar-brand {
        padding: 10px 0; } }

    .navbar-nav {
      padding: 20px 0; } }

  /* Banner */
  .slider-caption-one {
    h2 {
      @include font-size(50); }

    h3 {
      @include font-size(20);
      margin-bottom: 25px; } }


  .gp-bn-btn {
    padding: 10px 40px; }

  .switcher {
    bottom: 25px; }


  .slider-caption-two {
    left: 10%;

    h2 {
      @include font-size(35); }

    h4 {
      margin-bottom: 0; } }

  .slider-caption-three {
    left: 10%;

    h2 {
      @include font-size(35); }

    h4 {
      @include font-size(20);
      margin-bottom: 0; }

    h3 {
      @include font-size(22);
      margin-bottom: 1px; } }


  /* Skills */
  .our-skills {
    .skills-title {
      h2 {
        margin-bottom: 10px; }

      p {
        margin-bottom: 10px; } } }

  progress {
    height: 15px; }

  /* Service */
  .service-item {
    .service {
      .service_content {
        p {
          br {
            display: none; } } } } }

  /* Portfolio */
  .portfolio_container {
    .portfolio-item {
      .overlay {
        .work-caption {
          h2 {
            @include font-size(18); } } }

      &:hover {
        .overlay {
          .work-caption {
            .view {
              margin-bottom: 20px; } } } } } }


  /* Team */
  .team_member {
    border: 2px solid $color_theme;
    padding: 5px;
    .team_intro {
      padding: 10px 20px;

      span {
        margin-bottom: 10px; }

      .team_link {
        li {
          margin-right: 8px; } } }

    &:hover {
      .team_intro {
        h4 {
          margin-top: 0; } }

      .blog-thumb-left {
        .blog-comments {
          bottom: -34px;
          padding: 7px;
          li {
            padding: 0 3px; } } } } }

  /* Team Two */
  #team-slider {
    .team_member {
      &:hover {
        .team_intro {
          h4 {
            margin-top: 70px; } } } } }


  /* Contact */
  #form {
    input[type=text] {
      width: 47%; }

    /* Contact */ }
  #form {
    input[type=text] {
      width: 47%; }

    .gp-btn {
      margin-top: 18px; } } }

@media screen and (max-width: 991px) {

  /* Header */
  #header {
    .navbar-header {
      .navbar-brand {
        width: 150px;
        padding: 16px 0; } }

    .navbar-nav {
      li {
        padding: 0 6px; } } }

  /* Team Two */
  #team-slider {
    .team_member {
      &:hover {
        .team_intro {
          h4 {
            margin-top: 50px; } } } } }



  /* Countup Two */
  .countup-two {
    padding: 50px 0;
    .counter-box {
      margin-bottom: 15px; } }


  /* Contact Two  */
  #contact {
    &.contact-two {
      .contact-form-area {
        right: 40px; }

      .gmap3-area {
        height: 600px; } } } }



@media screen and (max-width: 768px) {

  /* Header */
  .header_wrapper {
    width: 100%;
    display: none; }


  #header {
    .navbar-header {
      .navbar-brand {
        padding: 0;
        margin-left: 20px; } }

    .navbar-collapse {
      padding: 0; }

    .navbar-nav {
      text-align: center;
      margin: 0; } }

  #mobile-header {
    display: block; }


  .navbar-toggle {
    margin: 12px 20px 12px 0; }

  .navbar-fixed-top {
    background: rgba(0, 0, 0, 0.8) !important;
    .navbar-header {
      .navbar-brand {
        padding: 0 !important; } } }


  .gp-primary-menu {
    background-color: transparent; }

  /* Banner */
  .swiper-banner {
    .swiper-slide {
      height: 370px;

      img {
        height: 100%;
        width: auto; } } }


  .slider-caption-one {
    h2 {
      @include font-size(30);

      &:before, &:after {
        width: 165px; } }

    h3 {
      @include font-size(18); } }

  .gp-bn-btn {
    padding: 10px 28px; }

  /* About */
  .about-box {
    margin-bottom: 50px; }

  /* Skills */
  .our-skills {
    .skills {
      display: block; }

    .skill-bar {
      padding: 50px 0; } }

  /* Skills two */
  .our-skills-two {
    padding: 50px 0; }

  .skills-description {
    padding: 0;

    h2 {
      margin-top: 20px; } }

  /* Service two */
  #service-two {
    padding: 50px 20px;

    .service {
      .overlay {

        padding: 50px 20px;

        p {
          br {
            display: none; } } } } }

  /* Counter */
  .counter-box {
    margin-bottom: 20px; }


  /* Portfolio */
  .portfolio_container {
    .portfolio-item {
      .overlay {
        .work-caption {
          width: 100%;
          .view {
            margin-bottom: 15px !important; } } } } }

  /* Blog Two  */
  .blog-two {
    .blog_post {
      .blog-thumb-left, .blog-thumb-right {
        float: none;
        width: 100%; }


      .blog_content {
        margin: 0 15px; }

      &.bolg-right {
        .blog_content {
          margin-right: 0; } } } }



  /* Team */

  .team_member {
    margin-bottom: 20px;

    .team_intro {
      bottom: -80%;
      span {
        margin-bottom: 20px; } }

    &:hover {
      .team_intro {

        h4 {
          margin-top: 85px; } } } }


  /* Team Two */
  #team-slider {
    .team_member {
      &:hover {
        .team_intro {
          h4 {
            margin-top: 50px; } } } } }

  /* Contact */
  #contact {
    .contact-inner {
      padding: 30px 0; } }

  .contact-form-area {
    .contact-info {
      .info {
        margin-bottom: 20px; } } }


  /* Contact Two */
  #contact {
    padding: 0;
    &.contact-two {
      .gmap3-area {
        height: 300px; }
      .contact-form-area {
        position: static;
        transform: translateY(0);
        width: 100%;
        background: #f5f5f5;



        #form {
          p {
            padding: 0 14px; } } } } }

  /* Newsletter */
  #newsletter {
    .news-wraper {
      form {
        width: 400px;
        margin: 0 auto; } } } }



@media screen and (max-width: 500px) {

  /* Section Title */
  .section-title {
    @include font-size(24); }

  /* Banner */
  .gp-bn-btn {
    margin-right: 10px;
    padding: 8px 16px; }

  .slider-caption-one {
    h3 {
      @include font-size(14); } }

  .slider-caption-two {
    h2 {
      @include font-size(25); }

    h4 {
      @include font-size(18); }

    p {
      @include font-size(14);
      br {
        display: none; } } }

  .slider-caption-three {
    h2 {
      @include font-size(22);
      margin-bottom: 15px; }

    h4 {
      @include font-size(18); }

    h3 {
      @include font-size(20); } }


  .swiper-pagination {
    right: 30px; }

  /* About */

  .caption {
    br {
      display: none; } }


  /* Skills Two */
  .our-skills-two {
    .skill-bar {
      padding-right: 0; } }


  /* CountUp */
  .full-width {
    width: 100%; }

  /* Team */
  .team_member {
    margin-bottom: 20px;
    .team_intro {
      bottom: -83%;
      span {
        margin-bottom: 20px; } }
    &:hover {
      .team_intro {
        h4 {
          margin-top: 135px; } } } }

  .portfolio {
    .portfolioFilter {
      li {
        margin-right: 5px;
        a {
          padding: 6px; } } } }

  /* Testimonial */
  #testimonial {
    .section-title {
      margin-bottom: 0; } }
  .testimonial-slider {
    .testional {
      .item {
        .testi-content {
          padding-top: 0;
          p {
            br {
              display: none; } } } } } }

  /* Newsletter */
  #newsletter {
    padding: 50px 0;
    .news-wraper {
      h3 {
        @include font-size(30); }

      form {
        width: 310px;
        margin: 0 auto;

        input[type=email] {
          padding: 10px 115px 10px 15px; }

        button {
          padding: 10px; } } } }



  /* Contact */
  #contact {
    .contact-inner {
      padding: 0;

      .contact-header {
        h2 {
          margin-top: 30px; } } }

    #form {
      input[type=text] {
        width: 100%; }

      .gp-btn {
        margin-bottom: 30px; } } }




  .return-to-top {
    right: 20px;
    bottom: 20px; }

  /* Contact Two */
  #contact {
    &.contact-two {
      .contact-form-area {
        padding: 20px 10px;
        #form {
          p {
            padding: 0; } } } } } }

/* Blog Signle */
.comment-respond {
  .comment-form {
    p {
      margin-right: 2px; } } }

/* Widget */
.widget_dt_search {
  .search-form {
    margin-top: 30px; } }



