#footer {
  padding-top: 50px;
  text-align: center;
  background: $color_theme;

  h2 {
    color: #FFF;
    font-weight: 600;
    @include font-size(24);
    margin-bottom: 30px; }

  .gp-social-link {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 50px;

    li {
      display: inline-block;
      padding-right: 10px;

      a {
        display: block;
        border: 1px solid #333;
        color: #333;
        height: 40px;
        width: 40px;
        @include font-size(20);
        line-height: 40px;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: #333;
          color: #FFF;
          border-color: transparent; } } } } }

.copyright {
  padding: 20px 0;
  border-top: 1px solid #13c5fd;
  text-align: center;

  p {
    color: #eee;
    margin-bottom: 0;
    padding-right: 10px;
    a {
      color: #fff;
      text-decoration: none;
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        height: 15px;
        width: 2px;
        background: #ddd;
        left: 10px;
        top: 2px; } } } }

/* Footer Two */
#footer.footer {
  background: #03141f;
  text-align: left;

  .copyright {
    border-color: #1d292d; }

  h3 {
    color: #FFF;
    font-weight: 700;
    @include font-size(22);
    text-transform: uppercase;
    margin-bottom: 30px; }

  .info {
    i {
      float: left;
      color: $color_theme;
      @include font-size(25);
      height: 40px;
      width: 40px; }

    p {
      color: #a9a9a9;
      @include line-height(22);
      margin-bottom: 20px; } }

  .gp-social-link {
    margin-top: 30px;
    li {
      a {
        text-align: center;
        color: #FFF;
        border-color: #FFF;
        height: 35px;
        width: 35px;
        @include font-size(18);
        line-height: 35px;

        &:hover {
          background: $color_theme;
          border-color: transparent; } } } }

  .news-wrapper {
    margin-bottom: 10px;
    border-bottom: 1px solid #0a2231;
    padding-bottom: 5px;

    &:last-child {
      border-bottom: none; }

    >a {
      display: block;
      float: left;
      transition: all 0.3s ease-in-out;
      width: 70px;
      height: 70px;

      img {
        width: 100%; } }

    .news-content {
      margin-left: 85px;
      a {
        span {
          color: #ddd;
          @include font-size(20);
          font-weight: 700;
          margin-bottom: 10px;
          display: block;
          transition: all 0.3s ease-in-out;
          @include line-height(15); }

        p {
          color: #a5a5a5;
          margin-bottom: 0;
          @include line-height(22); } } }

    &:hover {
      a {
        &:hover {
          opacity: 0.7; } }
      .news-content {
        a {
          text-decoration: none;

          span {
            color: $color_theme; } } } } } }

/* Flicker */

.flicker {
  a {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.7; } }

  .copyright {
    border-color: #0b2333;
    text-align: center;

    p {
      color: #929292; } } }

